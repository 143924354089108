import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { ExpandMore, ExpandLess, Delete } from "@material-ui/icons";
import "./reports.css";
import html2pdf from "html2pdf.js";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import LoadingButton from "../../loadingButton";

export default function Metrics({
  updateCompanyData,
  companyInfo,
  applicationObject,
  opportunityInfo,
  handleCreditChange,
}) {
  const [kpiGenerations, setKpiGenerations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedKpis, setExpandedKpis] = useState({});
  const [downloadingIndex, setDownloadingIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);
  const pdfRefs = useRef({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.metrics) {
      setKpiGenerations([...companyInfo.aiGenerations.metrics].reverse());
      setExpandedKpis(
        companyInfo.aiGenerations.metrics.reduce((acc, _, index) => {
          acc[index] = true;
          return acc;
        }, {}),
      );
    }
  }, [companyInfo]);

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/delete_ai_generation/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete metrics");
      }

      // Update local state
      setKpiGenerations((metrics) =>
        metrics.filter((metric) => metric._id !== id),
      );

      // Update company data
      const updatedMetrics = companyInfo.aiGenerations.metrics.filter(
        (metric) => metric._id !== id,
      );

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          metrics: updatedMetrics,
        },
      };

      updateCompanyData(companyInfo.applicant_company, updates);
    } catch (error) {
      console.error("Error deleting metrics:", error);
      setError("Failed to delete metrics. Please try again.");
    }
    setShowDeleteConfirm(null);
    setIsDeleting(false);
  };

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 100));
    }
    
    // Summarizing phase (5 seconds) 
    setGenerationStep("Summarizing");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 62.5));
    }

    // Outputting phase (remaining time)
    setGenerationStep("Outputting");
  };

  const fetchKpiData = async () => {
    setLoading(true);
    setError(null);
    setProgress(0);
    simulateProgress();

    try {
      const response = await axios.post(
        `/fetch_kpi_data?company_id=${companyInfo.applicant_company}&opportunity_id=${opportunityInfo._id}`,
        { applicationInfo: applicationObject },
      );

      if (response.status === 200 && response.data.metrics) {
        let fetchedKpiData = Array.isArray(response.data.metrics)
          ? response.data.metrics
          : [response.data.metrics];

        handleCreditChange(-1);

        setKpiGenerations((prevData) => {
          const uniqueNewKpiData = fetchedKpiData.filter(
            (newKpi) =>
              !prevData.some(
                (existingKpi) => existingKpi.created_on === newKpi.created_on,
              ),
          );

          setExpandedKpis((prevExpanded) => {
            const newExpandedState = { ...prevExpanded };
            uniqueNewKpiData.forEach((_, index) => {
              newExpandedState[index] = true;
            });
            return newExpandedState;
          });

          return [...uniqueNewKpiData, ...prevData];
        });

        const metricsWithId = fetchedKpiData.map((metric) => ({
          ...metric,
          _id: response.data.metrics._id,
        }));

        updateCompanyData(companyInfo.applicant_company, {
          aiGenerations: {
            ...companyInfo.aiGenerations,
            metrics: [
              ...metricsWithId,
              ...(companyInfo.aiGenerations.metrics || []),
            ],
          },
        });
        setProgress(100);
      }
    } catch (error) {
      console.error("Error fetching KPI data:", error);
      setError(
        "Due to high network load, we were unable to generate the metrics. Please try again later",
      );
    } finally {
      setLoading(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  const toggleKpiExpansion = (index) => {
    setExpandedKpis((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const downloadPdf = async (index) => {
    setDownloadingIndex(index);
    if (!pdfRefs.current[index]) {
      toggleKpiExpansion(index);
      await new Promise((resolve) => setTimeout(resolve, 300));
    }
    await initiateDownload(index);
    setDownloadingIndex(null);
  };

  const initiateDownload = async (index) => {
    const element = pdfRefs.current[index];
    if (!element) return;
    const heightPx = element.scrollHeight;
    const heightCM = heightPx / 35.35;

    const options = {
      filename: `${companyInfo.company_name} Metrics Report | StartupFuel.pdf`,
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7],
      },
    };

    try {
      await html2pdf().from(element).set(options).save();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setError("Failed to download PDF. Please try again.");
    }
  };

  const renderKpiDetails = (kpiObj, index) => {
    if (!kpiObj || kpiObj.length === 0) return null;

    return (
      <div className="report-container" key={index}>
        <div
          className="overall-summary"
          onClick={() => toggleKpiExpansion(index)}
        >
          <h2 className="overall-score">
            Metrics Report {kpiGenerations.length - index}:{" "}
            {expandedKpis[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="created-on">
              Date Created: {new Date(kpiObj.created_on).toLocaleDateString()}
            </p>
            <div className="score-button-container">
              <LoadingButton
                className="button button-secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadPdf(index);
                }}
                loading={downloadingIndex === index}
                text={
                  downloadingIndex === index ? "Downloading..." : "Download PDF"
                }
              />
              <button
                className="button button-danger ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteConfirm(kpiObj._id);
                }}
              >
                <Delete />
              </button>
            </div>
            {showDeleteConfirm === kpiObj._id && (
              <div style={{ textAlign: "left" }}>
                <p>Are you sure you want to delete this metrics report?</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    className="button button-danger mr-2"
                    onClick={() => handleDelete(kpiObj._id)}
                    loading={isDeleting}
                    text="Delete"
                  />
                  <button
                    className="button button-secondary"
                    onClick={() => setShowDeleteConfirm(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {expandedKpis[index] && (
          <div
            id={`metrics-content-${index}`}
            ref={(el) => (pdfRefs.current[index] = el)}
          >
            <PdfHeader
              company={companyInfo}
              created_on={kpiObj.created_on}
              docType="Metrics Report"
            />
            <div style={{ padding: "20px" }}>
              <table className="table-fixed w-100">
                <thead>
                  <tr className="border-bottom border-gray border-t">
                    <th className="py-4 font-bold text-sm">Title</th>
                    <th className="py-4 font-bold text-sm">Data</th>
                    <th className="py-4 font-bold text-sm">Description</th>
                  </tr>
                </thead>
                <tbody>
                  {kpiObj.metrics.map((kpi, idx) => (
                    <tr
                      className="border-bottom border-gray border-t"
                      key={idx}
                    >
                      <td className="py-4 text-sm">{kpi.title}</td>
                      <td className="py-4 text-sm">{kpi.data}</td>
                      <td className="py-4 text-sm">{kpi.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <PdfFooter />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Metrics</h4>
      <p className="text-grey text-sm">Displays the metrics of the company.</p>
      <div className="mr-4">
        <LoadingButton
          className="summarize-button"
          onClick={fetchKpiData}
          loading={loading}
          text={loading ? "Generating..." : "Generate Metrics"}
        />
      </div>

      {loading && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            {generationStep}...
          </div>
          <div style={{ 
            width: '100%', 
            height: '20px', 
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            overflow: 'hidden'
          }}>
            <div style={{
              width: `${progress}%`,
              height: '100%',
              background: 'linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)',
              transition: 'width 0.3s ease-in-out'
            }}></div>
          </div>
        </div>
      )}

      {error && (
        <p className="text-red-500 text-sm mt-2">Error: {error}</p>
      )}

      {kpiGenerations.length === 0 && !loading && !error && (
        <p className="text-red-500 text-sm mt-2">No metrics found.</p>
      )}

      {kpiGenerations.map((kpiObj, index) =>
        renderKpiDetails(kpiObj, index)
      )}
    </div>
  );
}
