import React from "react";
import FileViewer from "react-file-viewer";
import ReactMarkdown from 'react-markdown';

const FileViewerPopup = ({ fileUrl, onClose, isMarkdown }) => {
  const handleError = (e) => {
    console.error("Error in file viewer:", e);
    // You can add more error handling logic here if needed
  };

  // Function to extract file extension
  const getFileType = (url) => {
    // Check if url is a text string (not a file URL)
    if (isMarkdown) {
      return 'text';
    }
    console.log("File Url:", url);
    const extension = url.split(".").pop().toLowerCase();
    console.log("File type:", extension);
    return extension;
  };

  const fileType = getFileType(fileUrl);

  const renderContent = () => {
    if (isMarkdown) {
      return (
        <div style={{ padding: '20px' }}>
          <ReactMarkdown>{fileUrl}</ReactMarkdown>
        </div>
      );
    } else if (fileType === "pdf" || fileType === "ppt" || fileType === "pptx") {
      return (
        <FileViewer
          fileType={fileType}
          filePath={fileUrl}
          onError={handleError}
        />
      );
    } else if (
      fileType === "png" ||
      fileType === "jpeg" ||
      fileType === "jpg"
    ) {
      return (
        <img
          src={fileUrl}
          alt="Image Viewer"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        />
      );
    } else {
      return (
        <div style={{ textAlign: "center", padding: "16px" }}>
          <p>Unsupported file type.</p>
        </div>
      );
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          width: "90%",
          height: "90%",
          maxWidth: "1000px",
          maxHeight: "800px",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "16px",
            borderBottom: "1px solid #e5e7eb",
          }}
        >
          <h2
            style={{
              fontSize: "18px",
              fontWeight: "bold",
              margin: 0,
            }}
          >
            File Viewer
          </h2>
          <button
            onClick={onClose}
            style={{
              background: "none",
              border: "none",
              fontSize: "24px",
              cursor: "pointer",
              padding: "4px",
            }}
          >
            &times;
          </button>
        </div>
        <div
          style={{
            flexGrow: 1,
            overflow: "auto",
            padding: "16px",
          }}
        >
          {renderContent()}
        </div>
      </div>
    </div>
  );
};

export default FileViewerPopup;
