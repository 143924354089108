import React, { useState } from "react";
import "./uploadPopup.css"; // Import the CSS for styling
import axios from "axios"; // Axios for making API requests
import { Delete, CheckCircle } from "@material-ui/icons"; // Import icons
import LoadingButton from "../loadingButton";

const AddFiles = ({
  companyInfo,
  document_labels,
  file_type_mapping,
  closeModal,
  getFiles,
  updateCompanyData,
}) => {
  const [selectedLabel, setSelectedLabel] = useState("");
  const [otherLabel, setOtherLabel] = useState("");
  const [fileList, setFileList] = useState([]);
  const [errors, setErrors] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [selectedOperation, setSelectedOperation] = useState("upload"); // New state to track selected operation

  // Handle label change
  const handleLabelChange = (event) => {
    setSelectedLabel(event.target.value);
    if (event.target.value !== "Other") {
      setOtherLabel("");
    }
  };

  // Handle other label change
  const handleOtherLabelChange = (event) => {
    setOtherLabel(event.target.value);
  };

  // Handle file change
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const label = selectedLabel === "Other" ? otherLabel : selectedLabel;
    const fileType =
      selectedLabel === "Other"
        ? otherLabel
        : file_type_mapping[label] || `company ${label}`;

    if (!label) {
      e.target.value = null;
      setErrors({ ...errors, label: "Please select a label first." });
      return;
    }

    if (file) {
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const isImageFile = label === "Company Logo" || label === "Company Banner";
      const allowedExtensions = isImageFile
        ? ["png", "jpeg", "jpg"]
        : ["pdf", "pptx"];

      if (!allowedExtensions.includes(fileExtension)) {
        e.target.value = null;
        setErrors({
          ...errors,
          label: isImageFile
            ? `Only PNG, JPEG and JPG files are allowed. File '${file.name}' is not supported.`
            : `Only PDF and PPTX files are allowed. File '${file.name}' is not supported.`,
        });
        return;
      }

      const duplicate = fileList.some(
        (doc) => doc.file.name === file.name && doc.file_type === fileType,
      );
      if (duplicate) {
        setErrors({
          ...errors,
          duplicate: `Document type ${label} with this name already exists.`,
        });
        e.target.value = null;
        return;
      }

      const newDocument = {
        file,
        label,
        file_type: fileType,
        uploaded: false,
      };
      setFileList([...fileList, newDocument]); // Update the file list
      setSelectedLabel("");
      setOtherLabel("");
      setErrors({ ...errors, duplicate: null, label: null });
    }
  };

  // Handle file removal
  const handleRemoveFile = (index) => {
    setFileList((prevList) => prevList.filter((_, i) => i !== index));
  };

  // API Call for file upload
  const uploadFile = async (file, companyId, label, fileType) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", label.toLowerCase().replace(/ /g, "_"));
    formData.append("file_type", fileType);
    formData.append("object_ref", "company");
    formData.append("object_ref_value", companyId);

    try {
      const response = await axios.post(
        `/upload_file_secure?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        },
      );
      if (response.data.status === "success") {
        console.log(
          `Uploaded ${label} with URL: ${response.data.result.file_url}`,
        );

        // Call getFiles with the new file information
        getFiles({
          file_type: fileType,
          file_url: response.data.result.file_url,
          time_stamp: new Date().toISOString(),
          file_size: response.data.result.file_size,
        });

        return true;
      } else {
        console.error("Error uploading file:", response.data);
        return false;
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return false;
    }
  };

  // API Call for extract and delete
  const extractAndDeleteFile = async (
    file,
    companyId,
    fileType,
    updateCompanyData,
    companyInfo,
  ) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!["pdf", "pptx"].includes(fileExtension)) {
      setErrors((prev) => ({
        ...prev,
        label: `Only PDF and PPTX files are allowed for extraction. File '${file.name}' is not supported.`,
      }));
      return false;
    }

    try {
      // Create a FormData object to include the file, file_type, and other data
      const formData = new FormData();
      formData.append("file", file); // Attach the file
      formData.append("file_type", fileType); // Attach the file type
      formData.append("file_name", file.name); // Attach the file name

      // Make the API call using axios and send the form data
      const response = await axios.post(
        `/extract_file_content?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the correct content type for file upload
          },
        },
      );

      // Check if the response is successful
      if (response.status === 200) {
        console.log(`Extracted and deleted file: ${file.name}`);

        // Get the extracted content from the response
        const { extractedContent } = response.data;

        // Update the state using `updateCompanyData`
        const updatedContent = {
          ...companyInfo.content,
          extracted: {
            ...((companyInfo.content && companyInfo.content.extracted) || {}), // Safely spread existing or empty object
            [fileType]: extractedContent, // Add new extracted content for the file type
          },
        };
        updateCompanyData(companyId, { content: updatedContent }); // Update the company data
        return true;
      } else {
        console.error("Error extracting and deleting file:", response.data);
        return false;
      }
    } catch (error) {
      console.error("Error extracting and deleting file:", error);
      return false;
    }
  };

  // API Call for anonymize and delete
  const anonymizeAndDeleteFile = async (
    file,
    companyId,
    fileType,
    updateCompanyData,
    companyInfo,
  ) => {
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!["pdf", "pptx"].includes(fileExtension)) {
      setErrors((prev) => ({
        ...prev,
        label: `Only PDF and PPTX files are allowed for anonymization. File '${file.name}' is not supported.`,
      }));
      return false;
    }

    try {
      // Create a FormData object to include the file, file_type, and other data
      const formData = new FormData();
      formData.append("file", file); // Attach the file
      formData.append("file_type", fileType); // Attach the file type
      formData.append("file_name", file.name); // Attach the file name

      // Make the API call using axios and send the form data
      const response = await axios.post(
        `/anonymize_file_content?company_id=${companyId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Ensure the correct content type for file upload
          },
        },
      );

      // Check if the response is successful
      if (response.status === 200) {
        console.log(`Anonymized and deleted file: ${file.name}`);

        // Get the anonymized content from the response
        const { extractedContent } = response.data;

        // Update the state using `updateCompanyData`
        const updatedContent = {
          ...companyInfo.content,
          anonymized: {
            ...((companyInfo.content && companyInfo.content.extracted) || {}), // Safely spread existing or empty object
            [fileType]: extractedContent, // Add new extracted content for the file type
          },
        };
        updateCompanyData(companyId, { content: updatedContent }); // Update the company data
        return true;
      } else {
        console.error("Error anonymizing and deleting file:", response.data);
        return false;
      }
    } catch (error) {
      console.error("Error anonymizing and deleting file:", error);
      return false;
    }
  };

  // Submit handler
  const handleSubmit = async () => {
    setSubmitting(true);

    let allProcessed = true;
    for (let index = 0; index < fileList.length; index++) {
      const document = fileList[index];

      let result = false;
      switch (selectedOperation) {
        case "upload":
          result = await uploadFile(
            document.file,
            companyInfo.applicant_company,
            document.label,
            document.file_type,
          );
          break;
        case "extract_delete":
          result = await extractAndDeleteFile(
            document.file,
            companyInfo.applicant_company,
            document.file_type,
            updateCompanyData,
            companyInfo,
          );
          break;
        case "anonymize_delete":
          result = await anonymizeAndDeleteFile(
            document.file,
            companyInfo.applicant_company,
            document.file_type,
            updateCompanyData,
            companyInfo,
          );
          break;
        default:
          break;
      }

      if (!result) {
        allProcessed = false;
      }
    }

    setSubmitting(false);

    if (allProcessed) {
      closeModal();
    }
  };

  return (
    <div className="add-files-modal-wrapper">
      <div className="add-files-modal">
        <div className="modal-content">
          <button className="close-button" onClick={closeModal}>
            ✖
          </button>
          <h1>Upload Documents</h1>

          <div className="upload-container">
            <div className="upload-section">
              <label htmlFor="label-select">Select Document Type:</label>
              <select
                id="label-select"
                className="upload-select"
                value={selectedLabel}
                onChange={handleLabelChange}
                disabled={submitting}
              >
                <option value="" disabled>
                  Select a label
                </option>
                {document_labels.map((label) => (
                  <option key={label} value={label}>
                    {label}
                  </option>
                ))}
              </select>
              {errors.label && <span className="error">{errors.label}</span>}
              {selectedLabel === "Other" && (
                <input
                  type="text"
                  value={otherLabel}
                  onChange={handleOtherLabelChange}
                  placeholder="Enter label"
                  style={{ margin: "10px 0" }} // Inline style
                />
              )}
              <input
                id="file-upload"
                type="file"
                onChange={handleFileChange}
                disabled={submitting}
                accept={
                  selectedLabel === "Company Logo" || selectedLabel === "Company Banner"
                    ? ".png,.jpeg,.jpg"
                    : ".pdf,.pptx"
                }
                style={{ margin: "10px 0" }} // Inline style
              />
              {errors.duplicate && (
                <span className="error" style={{ color: "red" }}>
                  {errors.duplicate}
                </span>
              )}
            </div>
          </div>

          {/* Radio Buttons Section for File Operations */}
          <div style={{ margin: "20px 0" }}>
            <h3>File Operation</h3>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  value="upload"
                  checked={selectedOperation === "upload"}
                  onChange={() => setSelectedOperation("upload")}
                  style={{ marginRight: "10px" }}
                />
                Upload File
              </label>
              <label
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  value="extract_delete"
                  checked={selectedOperation === "extract_delete"}
                  onChange={() => setSelectedOperation("extract_delete")}
                  style={{ marginRight: "10px" }}
                />
                Extract and Delete File
              </label>
              <label
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="radio"
                  value="anonymize_delete"
                  checked={selectedOperation === "anonymize_delete"}
                  onChange={() => setSelectedOperation("anonymize_delete")}
                  style={{ marginRight: "10px" }}
                />
                Anonymize and Delete File
              </label>
            </div>
          </div>

          <hr />
          <h3>Selected Files</h3>
          <div className="upload-list">
            {fileList.length > 0 ? (
              fileList.map((doc, index) => (
                <div key={index} className="upload-item">
                  <span className="chip">{doc.label}</span>
                  <span className="file-name">{doc.file.name}</span>
                  {doc.uploaded ? (
                    <CheckCircle className="upload-tick" />
                  ) : (
                    <Delete
                      className="delete-icon"
                      onClick={() => handleRemoveFile(index)}
                    />
                  )}
                </div>
              ))
            ) : (
              <p>No files selected yet.</p>
            )}
          </div>
          <LoadingButton
            onClick={handleSubmit}
            loading={submitting}
            text={submitting ? "Processing..." : "Submit"}
            style={{
              marginTop: "20px",
              padding: "10px 20px",
              backgroundColor: "#007bff",
              color: "white",
              border: "none",
              cursor: "pointer",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AddFiles;
