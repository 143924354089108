import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";

import Stripe from "./live/stripe_page";

import PrivateRoute from "./live/components/PrivateRoute";

import Create_User_Company from "./live/create_user_company";
import Welcome from "./live/welcome";
import Applicant_Room from "./live/applicant_room_submissions";
import Applicant_Room_Team from "./live/applicant_room_team";
import Applicant_Room_Team_Management from "./live/applicant_room_team_management";
import Applicant_Room_Top from "./live/applicant_room_top";
import Applicant_Room_Referral_Partners from "./live/applicant_room_referral_partners";
import Applicant_Room_Settings from "./live/applicant_room_settings";
import Applicant_Room_Feed from "./live/applicant_room_feed.js";
import Applicant_Room_Dash from "./live/applicant_room_dash.js";
import Applicant_Room_portco from "./live/applicant_room_portco.js";
import Applicant_Room_Explore from "./live/applicant_room_explore.js";
import Applicant_Room_Portal from "./live/applicant_room_portal.js";

import Application_Room_Settings_Applicants from "./live/application_room_settings/applicant_room_settings_applicants.js";
import Application_Room_Settings_Judges from "./live/application_room_settings/applicant_room_settings_judges.js";
import Application_Room_Settings_Reviews from "./live/application_room_settings/applicant_room_settings_reviews.js";
import Application_Room_Add_Question from "./live/application_room_settings/applicant_room_settings_addQuestion";
import Application_Room_Settings_Visibility from "./live/application_room_settings/applicant_room_settings_visibility.js";
import Application_Room_Settings_PB from "./live/application_room_settings/settings_pb";
import Application_Room_Settings_Relevancy from "./live/application_room_settings/applicant_room_settings_relevancy";
import Application_Room_Settings from "./live/application_room_settings/applicant_room_settings.js";

import Applicant_Room_Flagged from "./live/applicant_room_flagged";
//import Create_User_Company_Merge from './live/create_user_company_merge'
import Create_Company from "./live/create_company";
import Admin_Create_Opportunity from "./live/admin_create_opportunity";
// import Create_Opportunity from './live/create_opportunity'
// import Edit_Opportunity from './live/edit_opportunity'
import Profile from "./live/company_profile";
//import Profile_Merge from './live/profile_merge'
import User_Profile from "./live/user_profile";
import Inbox from "./live/inbox";
import Opportunity from "./live/opportunity";
import Sign_In from "./live/sign_in";
import Register from "./test_mj-dev/pages/M-landingPage";
import Reset_Password from "./live/reset_password";
//import Explore_Merge from './live/explore_merge'
import Explore from "./live/explore";
import Added_Companies from "./live/added_companies";

import Opportunities from "./live/opportunities";
import Accelerators_Incubators from "./live/accelerators_incubators";
import Services from "./live/services";
import Products from "./live/toolbox";
import Admin_Create_User from "./live/admin_create_user";
import Admin_Manage_Companies from "./live/admin_manage_companies";
import Reset from "./live/reset";
import Investor_Register from "./live/investor_register_page";

import Landing_Page from "./test_mj-dev/pages/LandingPage";
import Landing_Page_Invited from "./test_mj-dev/pages/M-landingPageInvited";

import Services_Page from "./test_mj-dev/services_page";
import Terms_conditions_page from "./live/terms_conditions";
import Privacy_policy_page from "./live/privacy_policy";
import Aragon from "./pitch_box/Aragon";
import NewsFeed from "./newsfeed/NewsFeed";
import FollowingPage from "./newsfeed/FollowingPage";
import FollowerPage from "./newsfeed/FollowerPage";
import SocialOnboarding from "./newsfeed/SocialOnboardingContainer";
import Pitchbox from "./pitchboxStartup/Pitchbox";
import Olympus from "./Olympus/Olympus";
import Referral_Partners from "./live/referral_partners_stats";
import Create_Opportunity_Revised from "./live/application_room/create_opportunity/create_opp_container";
import Edit_Opportunity_Revised from "./live/application_room/create_opportunity/edit_opp_container";

import Community_Members from "./live/cmt/members";
import Community_NewsFeed from "./live/cmt/newsfeed";

import Services_Dashboard from "./live/services_dashboard";
import ERROR404 from "./live/404";
import Directory_Overview from "./live/directory_overview";

import Funding from "./live/funding";
import Dashboard from "./live/dashboard";
import Projects from "./live/projects";
import NewPitchBox from "./newPitchBox";
import BuyButton from "./live/components/BuyButton";
import Submit_opportunity from "./live/components/profiles/submit_opportunity/submit_opportunity";

class Router extends Component {
  render() {
    return (
      <App>
        <BrowserRouter>
          <React.StrictMode>
            <Switch>
              <Route path="/community_members" component={Community_Members} />
              <Route
                path="/community_newsfeed"
                component={Community_NewsFeed}
              />

              <Route path="/overview" component={Directory_Overview} />
              <Route path="/shop" component={BuyButton} />
              <Route path="/funding" component={Funding} />
              {/*<Route path="/dashboard" component={Dashboard} />*/}
              <Route path="/investor_register" component={Investor_Register} />
              <Route path="/register" component={Register} />

              <Route path="/pitchbox" component={NewPitchBox} />

              <Route path="/Olympus" component={Olympus} />
              <Route path="/stripe_confirmation" component={Stripe} />
              <Route path="/pitchboxstartup" component={Pitchbox} />
              <Route path="/newsfeed" component={NewsFeed} />
              <Route path="/followers" component={FollowerPage} />
              <Route path="/following" component={FollowingPage} />
              <Route path="/social_onboarding" component={SocialOnboarding} />
              <Route path="/argon" component={Aragon} />
              <Route
                path="/create_user_company"
                exact
                component={Create_User_Company}
              />
              <Route path="/welcome" exact component={Welcome} />
              <Route path="/pitchbox" exact component={Pitchbox} />
              {/*  <Route path='/create_user_company_merge' exact component={Create_User_Company_Merge}/> */}
              <Route path="/create_company" exact component={Create_Company} />
              <Route
                path="/admin/create_user"
                exact
                component={Admin_Create_User}
              />
              <Route
                path="/admin/manage_companies"
                exact
                component={Admin_Manage_Companies}
              />
              <Route
                path="/admin/create_opportunity"
                exact
                component={Admin_Create_Opportunity}
              />
              <Route
                path="/create_opportunity"
                exact
                component={Create_Opportunity_Revised}
              />
              <Route
                path="/edit_opportunity"
                exact
                component={Edit_Opportunity_Revised}
              />
              <Route path="/profile" exact component={Profile} />
              <Route path="/reset" exact component={Reset} />
              {/*   <Route path='/profile_merge' exact component={Profile_Merge}/> */}
              <Route path="/user_profile" exact component={User_Profile} />
              <Route exact path="/inbox" component={Inbox} />
              <Route exact path="/inbox/message" component={Inbox} />
              <Route path="/opportunity" exact component={Opportunity} />
              {/*<Route path='/opportunity_merge' exact component={Opportunity_Merge}/>  */}
              <Route path="/sign_in" exact component={Sign_In} />
              <Route path="/reset_password" exact component={Reset_Password} />
              <Route path="/explore" exact component={Explore} />
              <Route
                path="/Added_Companies"
                exact
                component={Added_Companies}
              />

              <Route path="/services" exact component={Services} />
              <Route path="/toolbox" exact component={Products} />
              <Route path="/projects" exact component={Projects} />
              {/*  <Route path='/explore_merge' exact component={Explore_Merge}/> */}
              <Route path="/opportunities" exact component={Opportunities} />
              <Route
                path="/submit_opportunity"
                exact
                component={Submit_opportunity}
              />
              <Route
                path="/accelerators"
                exact
                component={Accelerators_Incubators}
              />
              <PrivateRoute
                path="/applicant_room"
                exact
                component={Applicant_Room}
              />
              <PrivateRoute
                path="/applicant_room_portco"
                exact
                component={Applicant_Room_portco}
              />
              <PrivateRoute
                path="/applicant_room_dash"
                exact
                component={Applicant_Room_Dash}
              />
              <Route
                path="/applicant_room_portal"
                exact
                component={Applicant_Room_Portal}
              />
              <PrivateRoute
                path="/applicant_room_explore"
                exact
                component={Applicant_Room_Explore}
              />
              <PrivateRoute
                path="/applicant_room_feed"
                exact
                component={Applicant_Room_Feed}
              />
              <Route
                path="/applicant_room_team"
                exact
                component={Applicant_Room_Team}
              />
              <PrivateRoute
                path="/applicant_room_team_management"
                exact
                component={Applicant_Room_Team_Management}
              />
              <PrivateRoute
                path="/applicant_room_top"
                exact
                component={Applicant_Room_Top}
              />
              <PrivateRoute
                path="/applicant_room_referral_partners"
                exact
                component={Applicant_Room_Referral_Partners}
              />
              <PrivateRoute
                path="/applicant_room_flagged"
                exact
                component={Applicant_Room_Flagged}
              />
              <PrivateRoute
                path="/applicant_room_settings"
                exact
                component={Application_Room_Settings}
              />

              <PrivateRoute
                path="/applicant_room_settings_applicants"
                exact
                component={Application_Room_Settings_Applicants}
              />
              <PrivateRoute
                path="/applicant_room_settings_judges"
                exact
                component={Application_Room_Settings_Judges}
              />
              <PrivateRoute
                path="/applicant_room_settings_reviews"
                exact
                component={Application_Room_Settings_Reviews}
              />
              <PrivateRoute
                path="/applicant_room_settings_add_question"
                exact
                component={Application_Room_Add_Question}
              />
              <PrivateRoute
                path="/applicant_room_settings_visibility"
                exact
                component={Application_Room_Settings_Visibility}
              />
              <PrivateRoute
                path="/applicant_room_settings_pb"
                exact
                component={Application_Room_Settings_PB}
              />
              <PrivateRoute
                path="/applicant_room_settings_relevancy"
                exact
                component={Application_Room_Settings_Relevancy}
              />

              <Route path="/home_page" exact component={Landing_Page} />
              <Route path="/" exact component={Landing_Page} />
              <Route
                path="/invited_signup"
                exact
                component={Landing_Page_Invited}
              />
              <Route path="/services_page" exact component={Services_Page} />

              <Route
                path="/terms_conditions"
                exact
                component={Terms_conditions_page}
              />
              <Route
                path="/privacy_policy"
                exact
                component={Privacy_policy_page}
              />

              <Route
                path="/referral_partners_stats"
                exact
                component={Referral_Partners}
              />
              <Route
                path="/services_dashboard"
                exact
                component={Services_Dashboard}
              />
              <Route component={ERROR404} />
            </Switch>
          </React.StrictMode>
        </BrowserRouter>
      </App>
    );
  }
}

export default Router;
