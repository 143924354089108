import React, { useState, useEffect, useRef } from "react";
import { IconButton, CircularProgress } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import { TypeAnimation } from "react-type-animation"; // Import TypeAnimation
import "./diligenceChat.css";
import ReactMarkdown from "react-markdown";

export default function ChatComponent({
  companyInfo,
  opportunityInfo,
  updateCompanyData,
  applicationObject,
  handleCreditChange,
  user,
}) {
  const [chats, setChats] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [showUserMessage, setShowUserMessage] = useState(null); // Store the pending user message
  const chatBoxRef = useRef(null); // Reference for chat box to scroll to bottom
  const [lastAiMessageIndex, setLastAiMessageIndex] = useState(null); // Track the latest AI message index

  const defaultPrompts = [
    "What is the company's mission?",
    "Can you provide an overview of the company's financials?",
    "What are the key products or services offered?",
    "Who are the main competitors?",
    "What is the target market?",
  ];

  // Fetch chat data from the companyInfo prop
  useEffect(() => {
    if (
      companyInfo &&
      companyInfo.aiGenerations &&
      companyInfo.aiGenerations.chats
    ) {
      setChats(companyInfo.aiGenerations.chats[0]?.chat || []);
    }
  }, [companyInfo]);

  // Scroll to the bottom of the chat box when chats change
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [chats, showUserMessage]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) {
      setError("Message cannot be empty");
      return;
    }

    setLoading(true);
    setError("");
    setShowUserMessage(newMessage); // Show the user question with loading spinner

    try {
      const response = await fetch("/fetch_summarize", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          companyId: companyInfo.applicant_company,
          opportunityId: opportunityInfo._id,
          applicationObject: applicationObject,
          userId: user._id,
          question: newMessage,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text(); // Capture error message
        setError(`Error: ${errorText}`); // Display backend error message
        setLoading(false);
        return;
      }

      const data = await response.json();
      handleCreditChange(-1);

      // Clear user message after receiving the response
      setShowUserMessage(null);

      // Update the chats with the new message and response
      const newChatEntry = {
        userQuestion: newMessage,
        aiResponse: data.answer,
        timestamp: new Date(),
        userId: user._id,
      };

      setChats((prevChats) => [...prevChats, newChatEntry]);
      setLastAiMessageIndex(chats.length); // Track the index of the latest AI message

      // Update the company data in the parent component
      const updatedCompany = {
        ...companyInfo,
        aiGenerations: {
          ...companyInfo.aiGenerations,
          chats: [{ chat: [...chats, newChatEntry] }],
        },
      };

      updateCompanyData(companyInfo.applicant_company, updatedCompany);

      setNewMessage(""); // Clear input after successful send
    } catch (error) {
      console.error("Error sending message:", error);
      setError("Failed to send message. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  // Handle "Enter" key to send the message
  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default newline behavior
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <h4>Chat with DiligenceGPT</h4>
      <p className="text-grey text-sm">Ask questions about this company</p>

      {/* Chat display */}
      <div className="chat-box" ref={chatBoxRef}>
        {chats.length > 0 ? (
          chats.map((chat, index) => (
            <React.Fragment key={index}>
              {/* First show User Question (right) */}
              <div className="chat-message-container">
                <div className="chat-bubble-right">
                  <p>
                    <strong>{user.first_name}:</strong> {chat.userQuestion}
                  </p>
                  <p className="timestamp">
                    {new Date(chat.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
              {/* AI Response with typing effect only for the latest response */}
              <div className="chat-message-container">
                <div className="chat-bubble-left">
                  <p>
                    <strong>DiligenceGPT:</strong>{" "}
                    {index === lastAiMessageIndex ? (
                      <TypeAnimation
                        sequence={[chat.aiResponse]}
                        speed={85}
                        cursor={true}
                        repeat={0}
                      />
                    ) : (
                      <ReactMarkdown>{chat.aiResponse}</ReactMarkdown>
                    )}
                  </p>
                  <p className="timestamp">
                    {new Date(chat.timestamp).toLocaleString()}
                  </p>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div>
            <p>No chat history available.</p>
            <div style={{ marginTop: "20px", textAlign: "left" }}>
              <h5 style={{ marginBottom: "10px" }}>Quick Questions</h5>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  flexWrap: "wrap",
                }}
              >
                {defaultPrompts.map((prompt, index) => (
                  <button
                    key={index}
                    style={{
                      margin: "5px",
                      padding: "10px 15px",
                      backgroundColor: "#f0f0f0",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onClick={() => setNewMessage(prompt)}
                    onMouseOver={(e) =>
                      (e.target.style.backgroundColor = "#e0e0e0")
                    }
                    onMouseOut={(e) =>
                      (e.target.style.backgroundColor = "#f0f0f0")
                    }
                  >
                    {prompt}
                  </button>
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Show user message with loading if waiting for response */}
        {showUserMessage && (
          <>
            <div className="chat-message-container">
              <div className="chat-bubble-right">
                <p>
                  <strong>{user.first_name}:</strong> {showUserMessage}
                </p>
                {loading && <CircularProgress size={20} />}
              </div>
            </div>
          </>
        )}
      </div>

      {/* New message input */}
      <div className="chat-input-wrapper">
        <textarea
          placeholder="Ask a question"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyDown={handleKeyDown} // Trigger sending message on "Enter"
          className="standard-input"
          rows="1"
        />
        {error && <p className="error">{error}</p>}
        <IconButton
          onClick={handleSendMessage}
          color="primary"
          disabled={loading}
          className="send-icon"
        >
          {loading ? <CircularProgress size={24} /> : <Send />}
        </IconButton>
      </div>
    </div>
  );
}
