/* (2019-06-11) - This is the mobile review card that appears when a user clicks
'review' on the /submissions page or on the /applicant_room_top page.
*/
import React, { Component, Fragment, createRef } from "react";
import { file_type_mapping } from "../../../../boilerplate/list.json";
import StarRatings from "react-star-ratings";
import TextField from "@material-ui/core/TextField";
import Comment from "./../comment/Comment";
import "./ReviewCard.scss";
import Rating from "./Rating";
import { Button } from "@material-ui/core";
import URL from "url-parse";
import default_pic from "../../../../img/default_company_pic.png";
import default_background from "../../../../img/bg.png";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";
import classnames from "classnames";
import { NavLink } from "react-router-dom";
import { AiOutlineClose } from "react-icons/ai";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { ComponentToPrint } from "./ComponetPrint";
import TearsheetPdf from "./TearsheetPdf";
import { saveAs } from "file-saver";
import Tooltip from "@mui/material/Tooltip";
// import { PhotoIcon } from "@heroicons/react/24/solid";
import Grid from "@material-ui/core/Grid";
import {
  Info,
  RateReview,
  Assignment,
  Chat,
  DeleteForever,
  PersonAdd,
} from "@material-ui/icons";
import { FormControl } from "react-bootstrap";
import update from "immutability-helper";

import {
  pdf,
  // PDFDownloadLink,
  // PDFViewer
} from "@react-pdf/renderer";
import { Tune } from "@material-ui/icons";
import Tearsheet from "./reports/tearsheet";
import { TypeAnimation } from "react-type-animation";
import FileViewerPopup from "./FileViewer";
import DealInfo from "./dealInfo/dealInfo";
import ReportsMain from "./reports/reportsMain";
import InviteFounder from "./invite-founder";
import ChatComponent from "./diligenceChat";
import ChatLayout from "./reviewsCardContainer";

// import chatgpt from "../../../../../../node-chatgpt-api";

export default class ReviewsCard extends Component {
  constructor() {
    super();
    this.componentRef = createRef();

    this.state = {
      url: new URL(window.location.href, true),
      questions: [],
      apiquestions: [],
      isNewlyGenerated: false,
      showPdfViewer: false,
      viewFileUrl: "",
      animationComplete: {},
      startupScore: {},
      applicationObject: {},
      apianswer: "",
      question: "",
      answer: "",
      editingQuestionId: null,
      selectedFile: null,
      pitchdeckDetails: {
        file: "",
        file_name: "",
        file_type: "",
      },
      files: [],
      pitchdeckUrl: 0,
      UploadingDocument: false,
      isGenerating: false,
      startupScoreError: "",
    };
    this.props = {
      totalOverall: 0,

      totalIdea: 0,
      totalTechnology: 0,
      totalMarketing: 0,
      totalTeam: 0,
      overallRating: 5,
      comment: "",
      feedback: "",
      reviews: [],
      oppotunitydata: {},
      questinID: "",
      disableButton: false,
      answerGenetared: "",
      isSummarizing: false,
      isActiveeditField: "",
      answerValue: "",
      opportunity_formApi: [],
      FormDataOpper: {},
      questionTextNew: "",
      activeCompany: {},
      showFlagPopup: false,
      popup_body: " ",
      popup_header: " ",
      UpdateTearsheet: "",
    };
  }
  componentDidMount() {
    document.body.classList.add("no-scroll");
    console.log(this.state.url.query.opportunity_id);
    axios
      .get(
        `/fetch_opportunity?opportunity_id=${this.state.url.query.opportunity_id}`,
      )
      .then((response) => {
        console.log(response);
        this.setState({
          oppotunitydata: response.data.result,
        });
        console.log("this.state.opportunitydata");
        console.log(this.state.oppotunitydata);
      });

    this.fetchQuestionApi();
    this.fetchapiopperForm();
    this.updatePitchdeckUrl();
    this.getFiles();
    this.updateApplicationObject();
    const startupScore = this.props.selected_company?.[0]?.StartupScore;
    if (startupScore) {
      this.setState({ startupScore });
    }
  }

  componentWillUnmount() {
    document.body.classList.remove("no-scroll");
  }

  updateApplicationObject = () => {
    const applicationObject = {};

    Object.keys(this.props.opportunity_data.opportunity_form).forEach(
      (opp_form_key) => {
        if (
          this.props.opportunity_data.opportunity_form[opp_form_key][
            "display"
          ] === true
        ) {
          applicationObject[opp_form_key] = {
            Title:
              this.props.opportunity_data.opportunity_form[opp_form_key][
                "title"
              ],
            Answer: this.props.original_company[opp_form_key],
          };
        }
      },
    );

    console.log("Setting state with applicationInfo:", applicationObject);
    this.setState({ applicationObject });
  };

  handlePrint = () => {
    if (this.componentRef.current) {
      this.componentRef.current.handlePrint();
    }
  };
  handleFileChange = (event) => {
    this.setState({ selectedFile: event.target.files[0] });
  };

  handleUploadClick = () => {
    const { selectedFile } = this.state;
    if (selectedFile) {
      this.uploadPitchDeck(selectedFile);
    } else {
      alert("Please select a file to upload.");
    }
  };

  getFiles = (newfile) => {
    // Copy the existing files from the state
    const files = [...this.state.files];
    const fileTypes = new Set();

    if (newfile) {
      // Check if the new file's type already exists
      const existingFileIndex = files.findIndex(
        (file) => file.file_type === newfile.file_type,
      );

      if (existingFileIndex !== -1) {
        // Replace the existing file with the new file
        files[existingFileIndex] = {
          file_type: newfile.file_type,
          file_url: newfile.file_url,
          time_stamp: newfile.time_stamp,
          file_size: newfile.file_size,
        };
      } else {
        // Add the new file if its type doesn't exist
        files.push({
          file_type: newfile.file_type,
          file_url: newfile.file_url,
          time_stamp: newfile.time_stamp,
          file_size: newfile.file_size,
        });
      }
    } else {
      // Add existing files from props if no newfile is provided
      this.props.selected_company[0].company_files.forEach((file) => {
        files.push({
          file_type: file.file_type,
          file_url: file.file_url,
          time_stamp: file.time_stamp,
          file_size: file.file_size,
        });
        fileTypes.add(file.file_type);
      });
    }

    console.log(`Updated files ${files}`);

    // Update the state with the new files array
    this.setState({ files });
    this.props.updateCompanyData(
      this.props.selected_company[0].applicant_company,
      { company_files: files },
    );
  };

  deleteFile = async (fileType) => {
    const companyId = this.props.selected_company[0].applicant_company;
    this.setState({ loading: true }); // Set loading state

    try {
      const response = await axios.delete(
        `/delete_company_file?company_id=${companyId}`,
        {
          data: { file_type: fileType },
        },
      );

      if (response.data.status === "success") {
        console.log(`Deleted ${fileType}`);

        // Update the state to remove the deleted file
        this.setState((prevState) => ({
          files: prevState.files.filter((file) => file.file_type !== fileType),
          loading: false, // Reset loading state
        }));
      } else {
        console.error("Error deleting file:", response.data);
        this.setState({ loading: false }); // Reset loading state on error
      }
    } catch (error) {
      console.error("Error deleting file:", error);
      this.setState({ loading: false }); // Reset loading state on error
    }
  };

  updatePitchdeckUrl = () => {
    const pitchdeckFileUrl = this.getFileUrl(
      this.props.selected_company[0].company_files,
      "company pitchdeck",
    );
    console.log("pitchdeckFileUrl", pitchdeckFileUrl);
    this.setState({ pitchdeckUrl: pitchdeckFileUrl });
  };

  fetchapiopperForm = () => {
    axios
      .get(
        `get_id_company_from_opportunity?companyId=${this.props.selected_company[0]?.applicant_company}`,
      )
      .then((response) => {
        console.log("response 66 5", response);

        this.setState({
          FormDataOpper: response?.data,
        });
      });
  };

  fetchQuestionApi = () => {
    this.setState({ disableButton: true });
    axios.get(`/get_questions_all`).then((response) => {
      console.log("response 70", response);
      this.setState({
        apiquestions: response?.data?.questions,
        disableButton: false,
      });
    });
  };

  //   handleDeleteQuestion =(id)=>{

  //     axios.delete(`/questions/${id}`).then((response) => {
  //         if(response){
  //             this.fetchQuestionApi()
  //         }
  //       }).catch((error) => {
  //         console.error("Error deleting question:", error);
  //       });
  // }

  // handleSummarize = async (questionId, questionText) => {
  //   let query = 'Write an answer of question using summany.\n';
  //   if(!questionText){
  //     return false;
  //   }
  //   query += `Question: ${questionText}\n`;
  //   query += `Here is the Summary \n`
  //     // console.log("this.props.opportunity_data.opportunity_form",this.props.opportunity_data.opportunity_form)
  //   Object.keys(this.props.opportunity_data.opportunity_form).map((opp_form_key, i) => {
  //     if(this.props.opportunity_data.opportunity_form[opp_form_key]["display"] == true){
  //       query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //     }
  //   });
  //   console.log("query 160",query)
  //   axios.post(`/fetch_summarize`,{
  //     question:query
  //   })
  //   .then((response) => {
  //     console.log("response 142,", response);
  //     const newAnswers = { ...this.state.apianswer };
  //     newAnswers[questionId] = response.data.result;
  //     this.setState({ apianswer: newAnswers });
  //   });

  //   // axios.post(`/fetch_summarize`, {
  //   //   question: questionText,
  //   // }).then((response) => {
  //   //   console.log("response 142,", response);
  //   //   const newAnswers = { ...this.state.apianswer };
  //   //   newAnswers[questionId] = response.data.result;
  //   //   this.setState({ apianswer: newAnswers });
  //   // });
  // };

  // handleSummarizeAll = async () => {
  //   this.setState({ isSummarizing: true }); // Disable the button during API call

  //   const newAnswers = { ...this.state.apianswer };
  //   const { apiquestions } = this.state;
  //   for (const question of apiquestions) {
  //     if (!newAnswers[question._id]) {
  //       let query = "Write an answer of question using summary.\n";
  //       if (!question.question) {
  //         continue;
  //       }
  //       query += `Question: ${question.question}\n`;
  //       query += `Here is the Summary \n`;
  //       console.log("query 160", query);
  //       Object.keys(this.props.opportunity_data.opportunity_form).forEach(
  //         (opp_form_key) => {
  //           if (
  //             this.props.opportunity_data.opportunity_form[opp_form_key][
  //               "display"
  //             ] === true
  //           ) {
  //             query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //           }
  //         }
  //       );
  //       console.log("query 1450", query);
  //       const response = await axios.post(`/fetch_summarize`, {
  //         question: query,
  //       });
  //       console.log("response 185",response)
  //       newAnswers[question._id] = response.data.result;
  //     }
  //   }

  //   this.setState({ apianswer: newAnswers, isSummarizing: false });
  // };
  handleCreditChange = (value) => {
    this.props.modifyCredit(value);
  };

  handleStartupScore = async () => {
    const { applicationObject, pitchdeckUrl } = this.state;
    const companyId = this.props.selected_company[0].applicant_company;

    if (!applicationObject && !pitchdeckUrl) {
      this.setState({
        startupScoreError: "Application Info and Pitchdeck URL are missing.",
      });
      return;
    }

    if (this.props.opportunity_data.credits < 1) {
      this.setState({
        startupScoreError:
          "You do not have enough credits to generate a startup score.",
      });
      return;
    }

    this.setState({ isSummarizing: true, startupScoreError: "" }); // Disable the button during API call and clear previous errors

    try {
      const requestBody = {};

      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      if (pitchdeckUrl) {
        requestBody.pitchdeckUrl = pitchdeckUrl;
      }

      const response = await fetch(
        `/fetch_company_score?company_id=${companyId}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        },
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("Response from server:", data);

      this.handleCreditChange(-1);

      this.setState({
        startupScore: data.analysis,
        isNewlyGenerated: true,
      });
    } catch (error) {
      console.error("Error fetching company score:", error);
      this.setState({ startupScoreError: "Error fetching company score." });
    } finally {
      this.setState({ isSummarizing: false });
    }
  };

  styles = {
    reportContainer: {
      fontFamily: "Arial, sans-serif",
      margin: "0 auto",
      padding: "20px",
      backgroundColor: "#f9f9f9",
      borderRadius: "10px",
    },
    reportTitle: {
      textAlign: "center",
      color: "#333",
      marginBottom: "30px",
    },
    overallSummary: {
      backgroundColor: "#fff",
      padding: "20px",
      borderRadius: "8px",
      marginBottom: "30px",
    },
    overallSummaryTitle: {
      marginTop: "0",
      color: "#333",
    },
    score: {
      fontSize: "1.5em",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    summaryText: {
      lineHeight: "1.6",
      color: "#666",
    },
    categoryBreakdown: {
      marginBottom: "20px",
      color: "#333",
    },
    categoryItem: {
      backgroundColor: "#fff",
      padding: "15px",
      borderRadius: "8px",
      marginBottom: "15px",
    },
    categoryTitle: {
      marginTop: "0",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    scoreContainer: {
      display: "flex",
      alignItems: "center",
      margin: "10px 0",
    },
    scoreBar: {
      height: "20px",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      borderRadius: "10px",
    },
    scoreNumber: {
      marginLeft: "10px",
      fontWeight: "bold",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    categoryReason: {
      marginTop: "10px",
      color: "#666",
    },
    error: {
      color: "#d32f2f",
      backgroundColor: "#ffebee",
      padding: "10px",
      borderRadius: "4px",
      textAlign: "center",
      marginTop: "20px",
    },
    overallScore: {
      fontSize: "1.5em",
      fontWeight: "bold",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      WebkitBackgroundClip: "text",
      WebkitTextFillColor: "transparent",
      backgroundClip: "text",
    },
    overallScoreContainer: {
      width: "100%",
      backgroundColor: "#e0e0e0",
      borderRadius: "10px",
      margin: "10px 0",
    },
    overallScoreBar: {
      height: "20px",
      background: "linear-gradient(to right, #0047ab, #ff334b)",
      borderRadius: "10px",
    },
  };

  formatAnswer = (text) => {
    const parts = text.split(/(\*\*.*?\*\*)/g);
    return parts
      .map((part, index) => {
        if (part.startsWith("**") && part.endsWith("**")) {
          return part.slice(2, -2);
        }
        return part;
      })
      .join("");
  };

  renderGPTAnimation = (text, questionId) => {
    const formattedText = this.formatAnswer(text);

    return (
      <TypeAnimation
        sequence={[
          formattedText,
          () => {
            this.setState((prevState) => ({
              animationComplete: {
                ...prevState.animationComplete,
                [questionId]: true,
              },
              isNewlyGenerated: {
                ...prevState.isNewlyGenerated,
                [questionId]: false,
              },
            }));
          },
        ]}
        wrapper="span"
        cursor={true}
        speed={85}
        repeat={0}
        style={{ whiteSpace: "pre-line" }}
        dangerouslySetInnerHTML={{ __html: formattedText }}
      />
    );
  };

  /*renderTypingAnimation = (text, category) => (
    <TypeAnimation
      sequence={[
        text,
        () => {
          this.setState((prevState) => ({
            animationComplete: {
              ...prevState.animationComplete,
              [category]: true,
            },
          }));

          // Check if all animations are complete
          const allComplete = Object.values(this.state.animationComplete).every(
            (value) => value,
          );
          if (allComplete) {
            this.setState({ isNewlyGenerated: false });
          }
        },
      ]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  renderScoreDetails = () => {
    const { startupScore, startupScoreError } = this.state;

    if (startupScoreError) {
      return <p style={this.styles.error}>{startupScoreError}</p>;
    }

    if (!startupScore || Object.keys(startupScore).length === 0) return null;

    const categories = Object.keys(startupScore);
    const overallCategory =
      categories.find((cat) => cat.toLowerCase() === "overall") ||
      categories[0];

    return (
      <div style={this.styles.reportContainer}>
        <h1 style={this.styles.reportTitle}>Startup Score</h1>

        {startupScore[overallCategory] && (
          <div style={this.styles.overallSummary}>
            <h2 style={this.styles.overallSummaryTitle}>
              Overall Score:{" "}
              <span style={this.styles.overallScore}>
                {startupScore[overallCategory].Score
                  ? startupScore[overallCategory].Score.toFixed(1)
                  : "N/A"}
              </span>
            </h2>
            <div style={this.styles.overallScoreContainer}>
              <div
                style={{
                  ...this.styles.overallScoreBar,
                  width: `${(startupScore[overallCategory].Score || 0) * 10}%`,
                }}
              ></div>
            </div>
            <p style={this.styles.summaryText}>
              {this.state.isNewlyGenerated
                ? this.renderTypingAnimation(
                    startupScore[overallCategory].Reason ||
                      "No overall summary available.",
                    "overall",
                  )
                : startupScore[overallCategory].Reason ||
                  "No overall summary available."}
            </p>
            {startupScore[overallCategory].DataIntegrity && (
              <div style={this.styles.summaryText}>
                <h3 style={this.styles.summaryText}>
                  Data Integrity:{" "}
                  <span style={this.styles.summaryText}>
                    {startupScore[overallCategory].DataIntegrity.Score
                      ? `${Math.round(startupScore[overallCategory].DataIntegrity.Score)}%`
                      : "N/A"}
                  </span>
                </h3>
                <p style={this.styles.summaryText}>
                  {this.state.isNewlyGenerated
                    ? this.renderTypingAnimation(
                        startupScore[overallCategory].DataIntegrity.Reason ||
                          "No data integrity reason provided.",
                        "overallDataIntegrity",
                      )
                    : startupScore[overallCategory].DataIntegrity.Reason ||
                      "No data integrity reason provided."}
                </p>
              </div>
            )}
          </div>
        )}

        <div>
          <h2 style={this.styles.categoryBreakdown}>Category Breakdown</h2>

          {categories
            .filter((cat) => cat.toLowerCase() !== "overall")
            .map((category, index) => (
              <div key={index} style={this.styles.categoryItem}>
                <h3 style={this.styles.categoryTitle}>{category}</h3>
                <div style={this.styles.scoreContainer}>
                  <div
                    style={{
                      ...this.styles.scoreBar,
                      width: `${(startupScore[category].Score || 0) * 10}%`,
                    }}
                  ></div>
                  <span style={this.styles.scoreNumber}>
                    {startupScore[category].Score
                      ? startupScore[category].Score.toFixed(1)
                      : "N/A"}
                  </span>
                </div>
                <p style={this.styles.categoryReason}>
                  {this.state.isNewlyGenerated
                    ? this.renderTypingAnimation(
                        startupScore[category].Reason || "No reason provided.",
                        category,
                      )
                    : startupScore[category].Reason || "No reason provided."}
                </p>
                {startupScore[category].DataIntegrity && (
                  <div style={this.styles.categoryReason}>
                    <h4 style={this.styles.categoryReason}>
                      Data Integrity:{" "}
                      <span style={this.styles.categoryReason}>
                        {startupScore[category].DataIntegrity.Score
                          ? `${Math.round(startupScore[category].DataIntegrity.Score)}%`
                          : "N/A"}
                      </span>
                    </h4>
                    <p style={this.styles.categoryReason}>
                      {this.state.isNewlyGenerated
                        ? this.renderTypingAnimation(
                            startupScore[category].DataIntegrity.Reason ||
                              "No data integrity reason provided.",
                            `${category}DataIntegrity`,
                          )
                        : startupScore[category].DataIntegrity.Reason ||
                          "No data integrity reason provided."}
                    </p>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    );
  };*/

  // handleEditQuestion =(quetext,id)=>{
  //   this.setState({ question: quetext,editingQuestionId:true,questinID:id});

  // }

  // handleUpdateQuestion = (event) => {
  //   event.preventDefault();
  //   const {question,questinID} = this.state;
  //   axios.post(`/questions/${questinID}`, {
  //       question: question,
  //     }).then((response) => {
  //       this.fetchQuestionApi()
  //       this.setState({ question: "",editingQuestionId:false });
  //       console.log("edit response 142,", response);
  //     });

  // };

  // handleAddQuestion = (event) => {
  //   event.preventDefault();
  //   const { question } = this.state;
  //       console.log("question 240",question)
  //       if(question){
  //           axios.post(`/add_question_ai`,{
  //               question:question
  //             })
  //             .then((response) => {
  //                 console.log("response 245",response);
  //               if(response){
  //               this.setState({ question: "" });
  //               this.fetchQuestionApi()
  //               }
  //             });
  //       }
  // };

  getAvatarUrl = (array, file_type, temp_background_url) => {
    let new_arr = array.filter((file) => {
      return file.file_type === file_type;
    });
    if (file_type === "company avatar" || file_type === "user avatar") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        return default_pic;
      }
    }
    if (file_type === "company background") {
      if (new_arr.length !== 0) {
        return new_arr[0].file_url;
      } else {
        if (
          temp_background_url !== undefined &&
          temp_background_url !== null &&
          temp_background_url.length > 0
        ) {
          return temp_background_url;
        } else {
          return default_background;
        }
      }
    }
  };

  // handleAddQuestion = (event) => {
  //   event.preventDefault();
  //   const { questions, question, answer } = this.state;
  //   if (!question) {
  //     return false;
  //   }
  //   const newQuestion = {
  //     id: questions.length + 1,
  //     question,
  //     answer,
  //   };
  //   this.setState({
  //     questions: [...questions, newQuestion],
  //     question: "",
  //     answer: "",
  //   });
  // };

  handleEditQuestion = (id) => {
    const { questions } = this.state;
    const question = questions.find((q) => q.id === id);
    this.setState({
      question: question.question,
      answer: question.answer,
      editingQuestionId: id,
    });
  };

  handleUpdateQuestion = (event) => {
    event.preventDefault();
    const { questions, question, answer, editingQuestionId } = this.state;
    if (!question) {
      return false;
    }
    const updatedQuestions = questions.map((q) =>
      q.id === editingQuestionId ? { ...q, question, answer } : q,
    );
    this.setState({
      questions: updatedQuestions,
      question: "",
      answer: "",
      editingQuestionId: null,
    });
  };

  redirectToAddQuestion = () => {
    const { opportunity_id } = this.state.url.query;
    window.location.href = `/applicant_room_settings_add_question?opportunity_id=${opportunity_id}`;
  };

  handleDeleteQuestion = (id) => {
    const { questions } = this.state;
    const updatedQuestions = questions.filter((q) => q.id !== id);
    this.setState({
      questions: updatedQuestions,
    });
  };

  handleQuestionChange = (event) => {
    this.setState({ question: event.target.value });
  };

  // handleAnswerChange = (event) => {
  //   this.setState({ answer: event.target.value });
  // };

  handleAddQuestion = (newQuestion) => {
    const { questions } = this.state;
    this.setState({
      questions: [...questions, newQuestion],
    });
  };
  updateQuestionWithAnswer = (questionId, answer) => {
    this.setState((prevState) => ({
      questions: prevState.questions.map((question) => {
        if (question.id === questionId) {
          return { ...question, answer };
        }
        return question;
      }),
    }));
  };

  uploadPitchDeck = (file) => {
    // Basic validation to ensure a file is selected
    if (!file) {
      console.error("No file selected for upload.");
      return;
    }

    // Creating FormData to send the file
    const formData = new FormData();
    formData.append("file", file);
    formData.append("file_name", "pitchdeck");
    formData.append("file_type", "company pitchdeck");
    formData.append("object_ref", "company");
    formData.append(
      "object_ref_value",
      this.props.selected_company[0].applicant_company,
    );

    // Configurations for the axios request
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    console.log("formData", formData);
    console.log(
      `Calling Upload pitchdeck api: /upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`,
    );

    // Perform the file upload via axios
    axios
      .post(
        `/upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`,
        formData,
        config,
      )
      .then((response) => {
        if (response.data.status === "success") {
          // Assuming fetchData, getAvatarUrl, and computeProfileCompleteness are methods that update your app's state or UI
          this.fetchData();
          window.location.reload();

          // Update the state to reflect the new pitchdeck info if similar file_type already exists
          const fileType = "company pitchdeck";
          const index = this.state.company_files.findIndex(
            (item) => item.file_type === fileType,
          );
          if (index !== -1) {
            this.setState({
              company_files: this.state.company_files.map((item, idx) =>
                idx === index
                  ? { ...item, file_url: response.data.result.file_url }
                  : item,
              ),
              showEditPopup: false,
            });
          } else {
            // If no existing pitchdeck, just add the new one
            this.setState({
              company_files: [
                ...this.state.company_files,
                {
                  file_type: fileType,
                  file_url: response.data.result.file_url,
                },
              ],
              showEditPopup: false,
            });
          }
        } else {
          console.error("Upload failed:", response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error uploading file:", error);
      });
  };

  getFileUrl = (array, file_type) => {
    if (array?.length > 0) {
      let new_arr = array.filter((file) => {
        return file.file_type === file_type;
      });
      if (new_arr.length !== 0) {
        console.log("file_url", new_arr[0].file_url);
        return new_arr[0].file_url;
      }
    } else {
      console.log(`No ${file_type} found`);
      return 0;
    }
  };

  handleFileUpload = (e) => {
    let file = e.target.files[0];
    let that = this;
    if (e.target.dataset.fileType === "pitchdeck" && this.props.isLoggedIn) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
          object_ref_value: this.props.selected_company[0].applicant_company,
        },
      });
    } else if (
      e.target.dataset.fileType === "pitchdeck" &&
      !this.props.isLoggedIn
    ) {
      this.setState({
        pitchdeckDetails: {
          file,
          file_name: "pitchdeck",
          file_type: `company ${e.target.dataset.fileType}`,
          object_ref: "company",
        },
      });
    }
  };

  handleFileSubmit = (type, company_id) => {
    this.setState({
      UploadingDocument: true,
    });
    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    let formData = new FormData();

    for (let key in this.state[type]) {
      formData.append(key, this.state[type][key]);
    }
    if (typeof this.state[type].file === "object") {
      axios
        .post(
          `/upload_file_secure?company_id=${this.props.selected_company[0].applicant_company}`,
          formData,
          config,
        )
        .then(
          (response) => {
            if (response.data.status === "success") {
              //if (this.state.company_files.length > 0){

              this.setState({
                pitchdeckUrl: response.data.result.file_url,
                UploadingDocument: false,

                // [type]: {
                //   file: '',
                //   file_name: '',
                //   file_type: ''
                // }

                showEditPopup: false,
              });
              console.log(`Updated pitchdeck url: ${this.state.pitchdeckUrl}`);
            } else {
              this.setState({
                company_files: [
                  ...this.state.company_files,
                  ...[
                    {
                      file_type: response.data.result.file_type,
                      file_url: response.data.result.file_url,
                    },
                  ],
                ],

                showEditPopup: false,
              });
            }
          }, // do else later
        )
        .catch((error) => {
          console.log(error);
        });
    } else {
      return;
    }
  };

  handleSummarizeNew = async (event, questionId) => {
    event.preventDefault();
    const question = event.target.getAttribute("data-question");
    if (!question) {
      return false;
    }
    this.handleAddQuestion({ id: questionId, question });
    this.setState({ question: "" });
    if (this.props.opportunity_data.credits < 1) {
      this.updateQuestionWithAnswer(
        questionId,
        "You do not have enough credits to use diligenceGPT.",
      );
      return;
    }
    const { applicationObject, pitchdeckUrl } = this.state;
    const companyId = this.props.selected_company[0].applicant_company;
    const opportunityId = this.state.oppotunitydata._id;
    const userId = this.props.user._id;

    const requestBody = { question, companyId, opportunityId, userId };

    // Check if applicationObject has valid answers
    const hasValidApplicationObjectAnswers =
      applicationObject &&
      Object.values(applicationObject).some(
        (answer) => typeof answer === "string" && answer.trim() !== "",
      );

    if (hasValidApplicationObjectAnswers) {
      requestBody.applicationObject = applicationObject;
    }

    if (pitchdeckUrl) {
      requestBody.pitchdeckUrl = pitchdeckUrl;
    }

    axios
      .post(`/fetch_summarize`, requestBody)

      .then((response) => {
        // Update the state to save the answer with the corresponding question ID
        this.handleCreditChange(-1);
        this.updateQuestionWithAnswer(questionId, response.data.answer);
      })
      .catch((error) => {
        console.error("Error fetching summary:", error);
        this.updateQuestionWithAnswer(
          questionId,
          "Unable to generate insights. Please try again later.",
        );
      });
  };

  // handleSummarizeNew = async (event) => {
  //   let query = "Write an answer of question using summany.\n";
  //   const question = event.target.getAttribute("data-question");
  //   if (!question) {
  //     return false;
  //   }

  //   query += `Question: ${question}\n`;
  //   query += `Here is the Summary \n`;
  //   console.log("query 155", query);
  //   Object.keys(this.props.opportunity_data.opportunity_form).map(
  //     (opp_form_key, i) => {
  //       if (
  //         this.props.opportunity_data.opportunity_form[opp_form_key][
  //         "display"
  //         ] == true
  //       ) {
  //         query += `${this.props.opportunity_data.opportunity_form[opp_form_key]["title"]}:${this.props.original_company[opp_form_key]}, \n`;
  //       }
  //     }
  //   );
  //   console.log("query 160", query);
  //   axios
  //     .post(`/fetch_summarize`, {
  //       question: query,
  //     })
  //     .then((response) => {
  //       console.log(response);
  //       this.setState({ answer: response.data.result });
  //     });
  // };

  handleDealInfo = (id) => {
    console.log("id", id);
    this.setState({ isActiveeditField: id });
  };

  handleAnsDeal = (e, id) => {
    let value = e.target.value;
    this.setState({ questionTextNew: value });
    if (value) {
      let obj = {
        ...this.props.opportunity_data.opportunity_form[id],
        ["helper_text"]: value,
      };
      this.setState({
        opportunity_formApi: {
          ...this.props.opportunity_data.opportunity_form,
          [id]: obj,
        },
      });
    }
  };

  handleFormSave = (fieldName) => {
    const { questionTextNew } = this.state;
    console.log("questionTextNew", questionTextNew);
    if (questionTextNew) {
      axios.put(`/edit_company_from_opportunity`, {
        helper_text: questionTextNew,
        companyId: this.props.selected_company[0].applicant_company,
        fieldName: fieldName,
      });
      this.fetchapiopperForm();
    }
    this.fetchapiopperForm();
    setTimeout(() => {
      this.setState({ isActiveeditField: null });
    }, 1000);
    // this.props.handleHide()
  };
  handleButtonClick = () => {
    // Call the handleSubmit function in the child component
    setTimeout(() => {
      this.componentRef.handleSubmit();
    }, 100);
  };
  handleClickGenerate = () => {
    this.setState({ isGenerating: true });
    console.log("Generating...");

    setTimeout(() => {
      this.componentRef.handleGenerate();

      this.setState({ isGenerating: false });
      console.log("Set isgenerating to false");
    }, 100);
  };
  setUpdateTearsheet = (value) => {
    this.setState({ UpdateTearsheet: value, refState: false });
  };

  handleVisibilityClick = (fileurl) => {
    this.setState({ viewFileUrl: fileurl });

    this.setState({ showPdfViewer: true });
  };

  handlePdfViewerClose = () => {
    this.setState({ showPdfViewer: false });
  };

  render() {
    const { pitchdeckUrl, showPdfViewer } = this.state;
    const hasReviewed = this.props.current_judge_has_reviewed;
    const {
      questions,
      question,
      answer,
      editingQuestionId,
      disableButton,
      apianswer,
      apiquestions,
      isActiveeditField,
      answerValue,
      FormDataOpper,
      refState,
    } = this.state;

    const stages = [
      {
        label: "New",
        value: "new",
      },
      {
        label: "Review",
        value: "review",
      },
      {
        label: "First Diligence",
        value: "firstdiligence",
      },
      {
        label: "Deep Diligence",
        value: "deepdilligence",
      },
      {
        label: "Offer",
        value: "offer",
      },
      {
        label: "Portco",
        value: "portco",
      },
    ];

    const currentStage =
      this.props.selected_company[0].application_status || "new";

    const currentIndex = stages.findIndex(
      (stage) => stage.value === currentStage,
    );

    const activeStage =
      this.props.updateStatus?.application_status || currentStage;

    // console.log("answer 239", answer);
    // console.log("FormDataOpper 239", FormDataOpper);

    const changeTab = (tab) => {
      if (tab == "tab0") {
        document.getElementById("tab_btn_0").classList.add("active");
        document.getElementById("tab0").classList.add("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab1") {
        document.getElementById("tab_btn_1").classList.add("active");
        document.getElementById("tab1").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab2") {
        document.getElementById("tab_btn_2").classList.add("active");
        document.getElementById("tab2").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }
      if (tab == "tab3") {
        document.getElementById("tab_btn_3").classList.add("active");
        document.getElementById("tab3").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_4").classList.remove("active");
        document.getElementById("tab4").classList.remove("active");
      }

      if (tab == "tab4") {
        document.getElementById("tab_btn_4").classList.add("active");
        document.getElementById("tab4").classList.add("active");

        document.getElementById("tab_btn_0").classList.remove("active");
        document.getElementById("tab0").classList.remove("active");

        document.getElementById("tab_btn_1").classList.remove("active");
        document.getElementById("tab1").classList.remove("active");

        document.getElementById("tab_btn_2").classList.remove("active");
        document.getElementById("tab2").classList.remove("active");

        document.getElementById("tab_btn_3").classList.remove("active");
        document.getElementById("tab3").classList.remove("active");
      }
    };

    return (
      <div className="__review-card">
        {showPdfViewer && (
          <FileViewerPopup
            fileUrl={this.state.viewFileUrl}
            fileType="pdf"
            onClose={this.handlePdfViewerClose}
          />
        )}

        <div className="__model" onClick={this.props.handleHide}></div>
        <div className="__pb_deal-card p-3">
          <div className="__pb_deal-profile"></div>
          <div className="__main">
            <div className="__deal_page_header">
              <div className="__header-left flex">
                <div className="__company-image">
                  <div>
                    <img
                      style={{ width: "100%", height: "100%" }}
                      src={this.props.getAvatarUrl(
                        this.props.selected_company[0].company_files,
                        "company avatar",
                      )}
                    ></img>
                  </div>
                </div>
                <div className="__company-info">
                  <h3 className="__title">
                    {this.props.selected_company[0].company_name}
                  </h3>
                  <p className="__subtitle">
                    {this.props.selected_company[0].company_website &&
                      this.props.selected_company[0].company_website.length !==
                        0 && (
                        <a
                          href={
                            this.props.selected_company[0].company_website.includes(
                              "http",
                            )
                              ? this.props.selected_company[0].company_website
                              : `http://${this.props.selected_company[0].company_website}`
                          }
                          target="_blank"
                        >
                          {this.props.selected_company[0].company_website}
                        </a>
                      )}
                    {this.props.selected_company[0].company_website &&
                      this.props.selected_company[0].company_website.length !==
                        0 &&
                      this.props.selected_company[0].formatted_address &&
                      this.props.selected_company[0].formatted_address
                        .length !== 0}
                  </p>
                </div>
              </div>
              {/* <div class="" role="group" aria-label="Basic example">

                <button type="button" class="btn btn-sm btn-white">New</button>
                <button type="button" class="btn btn-sm btn-white">Reviewing</button>
                <button type="button" class="btn btn-sm btn-white">1st Diligence</button>
                <button type="button" class="btn btn-sm btn-white">Deep Diligence</button>
                <button type="button" class="btn btn-sm btn-white">Offer</button>
              </div> */}
              <div className="__header-middle ml-2 border-3">
                {/* <div className="progress-container">
                  <div>
                    <ol className="progress-steps">
                      {stages.map((stage, index) => (
                        <li
                          onClick={(event) =>
                            currentIndex + 1 === index &&
                            this.props.openTableModal(
                              this.props.selected_company[0],
                            )
                          }
                          key={stage.value}
                          className={`progress-step ${activeStage === stage.value ? "active" : ""}`}
                          style={{
                            cursor:
                              currentIndex + 1 === index
                                ? "pointer"
                                : "default",
                          }}
                        >
                          <p className="step-label">
                            <strong>{stage.label}</strong>
                          </p>
                        </li>
                      ))}
                    </ol>
                  </div>
                </div> */}
              </div>
              <div className="__header-right mr-3">
                <div
                  className="close-icon-main-wraper h-4"
                  onClick={this.props.handleHide}
                >
                  <AiOutlineClose />
                </div>
              </div>
            </div>

            <div class="info_review_modal_inner">
              <div class="tearsheet-new">
                {/*/!* Company cover image *!/*/}
                {/*{this.props.selected_company[0].pitch_comp_video_link !==*/}
                {/*  undefined && this.props.opportunity_data.enter_pitch_video ? (*/}
                {/*  <iframe*/}
                {/*    src={this.props.selected_company[0].pitch_comp_video_link}*/}
                {/*    frameborder="0"*/}
                {/*    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*    allowfullscreen*/}
                {/*  ></iframe>*/}
                {/*) : this.props.selected_company[0].background_video_link !==*/}
                {/*    undefined &&*/}
                {/*  this.props.selected_company[0].background_video_link !==*/}
                {/*    null &&*/}
                {/*  this.props.selected_company[0].background_video_link.length >*/}
                {/*    0 ? (*/}
                {/*  <iframe*/}
                {/*    src={this.props.selected_company[0].background_video_link}*/}
                {/*    frameborder="0"*/}
                {/*    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"*/}
                {/*    allowfullscreen*/}
                {/*  ></iframe>*/}
                {/*) : (*/}
                {/*  <img*/}
                {/*    src={this.props.getAvatarUrl(*/}
                {/*      this.props.selected_company[0].company_files,*/}
                {/*      "company background",*/}
                {/*      this.props.selected_company[0].temp_background_url,*/}
                {/*    )}*/}
                {/*    alt="hero image"*/}
                {/*  />*/}
                {/*)}*/}

                {/*<h4 className="__company-name">*/}
                {/*  <a*/}
                {/*    href={*/}
                {/*      "/profile?company_id=" +*/}
                {/*      this.props.selected_company[0].applicant_company*/}
                {/*    }*/}
                {/*    target="_blank"*/}
                {/*  >*/}
                {/*    {this.props.selected_company[0].company_name}*/}
                {/*  </a>*/}
                {/*</h4>*/}

                {/*<h4 style={{ textAlign: "center" }}>*/}
                {/*  AI Credits: {this.props.opportunity_data.credits}*/}
                {/*</h4>*/}

                {/* {console.log("this.props.selected_company",this.props?.selected_company[0])} */}
                {/*{this.state.oppotunitydata &&*/}
                {/*  typeof this.state.oppotunitydata.opportunity_form ===*/}
                {/*    "object" &&*/}
                {/*  Object.keys(this.state.oppotunitydata.opportunity_form).map(*/}
                {/*    (opp_form_key, i) =>*/}
                {/*      this.state.oppotunitydata.opportunity_form[opp_form_key][*/}
                {/*        "display"*/}
                {/*      ] === true && (*/}
                {/*        <div class="mt-4">*/}
                {/*          <h6>*/}
                {/*            {*/}
                {/*              this.state.oppotunitydata.opportunity_form[*/}
                {/*                opp_form_key*/}
                {/*              ]["title"]*/}
                {/*            }*/}
                {/*          </h6>*/}
                {/*          <p>{this.props.selected_company[0][opp_form_key]}</p>*/}
                {/*        </div>*/}
                {/*      ),*/}
                {/*  )}*/}
                {/*{console.log(*/}
                {/*  "updateStatus 720",*/}
                {/*  this.props.updateStatus?.application_status,*/}
                {/*)}*/}
                {/*<div*/}
                {/*  variant="contained"*/}
                {/*  className="__status-dropdown stag-popup-deail"*/}
                {/*  onClick={(event) =>*/}
                {/*    this.props.openTableModal(this.props.selected_company[0])*/}
                {/*  }*/}
                {/*  size="small"*/}
                {/*>*/}
                {/*  <h6 className="mb-0 text-sm underline">*/}
                {/*    {this.props.updateStatus?.application_status ? (*/}
                {/*      <span>{this.props.updateStatus?.application_status}</span>*/}
                {/*    ) : (*/}
                {/*      <>*/}
                {/*        {!this.props.selected_company[0].application_status ? (*/}
                {/*          <span>New</span>*/}
                {/*        ) : null}*/}
                {/*        {stages.map((stage) =>*/}
                {/*          this.props.selected_company[0].application_status ===*/}
                {/*          stage.value ? (*/}
                {/*            <span key={stage.value}>{stage.label}</span>*/}
                {/*          ) : null,*/}
                {/*        )}*/}
                {/*      </>*/}
                {/*    )}*/}
                {/*  </h6>*/}
                {/*</div>*/}

                {/*Deal Noted UI Below*/}

                {/*{typeof FormDataOpper?.deal_notes === "object" && (*/}
                {/*  //  FormDataOpper?.deal_notes.title*/}
                {/*  // Object.keys(FormDataOpper?.opportunity_form).map(*/}
                {/*  //   (opp_form_key, i) =>*/}
                {/*  // FormDataOpper?.opportunity_form[opp_form_key]["display"] === true && (*/}
                {/*  <div*/}
                {/*    className="border-2"*/}
                {/*    onTouchStart={() =>*/}
                {/*      this.setState({*/}
                {/*        isActiveeditField: FormDataOpper?.deal_notes,*/}
                {/*      })*/}
                {/*    }*/}
                {/*    onClick={() =>*/}
                {/*      this.setState({*/}
                {/*        isActiveeditField: FormDataOpper?.deal_notes,*/}
                {/*      })*/}
                {/*    }*/}
                {/*    onBlur={() =>*/}
                {/*      this.handleDealInfo(FormDataOpper?.deal_notes)*/}
                {/*    }*/}
                {/*  >*/}
                {/*    {isActiveeditField === FormDataOpper?.deal_notes ? (*/}
                {/*      <>*/}
                {/*        <TextField*/}
                {/*          // disabled*/}
                {/*          rows="4"*/}
                {/*          style={{ width: "100%", height: "100px" }}*/}
                {/*          variant="outlined"*/}
                {/*          multiline*/}
                {/*          value={answerValue}*/}
                {/*          onBlur={(event) => {*/}
                {/*            this.handleAnsDeal(*/}
                {/*              event,*/}
                {/*              FormDataOpper?.deal_notes,*/}
                {/*            );*/}
                {/*            this.handleFormSave("deal_notes");*/}
                {/*          }}*/}
                {/*          placeholder={FormDataOpper?.deal_notes?.helper_text}*/}
                {/*        />*/}
                {/*        /!* <div className="button-edit-dealinfo">*/}
                {/*                <div className="button-save-dealinfo justify-en">*/}
                {/*                  <Button*/}
                {/*                    variant="contained"*/}
                {/*                    color="primary"*/}
                {/*                    onClick={() => this.handleFormSave("deal_notes")}*/}
                {/*                  >*/}
                {/*                    Save*/}
                {/*                  </Button>*/}
                {/*                </div>*/}
                {/*              </div> *!/*/}
                {/*      </>*/}
                {/*    ) : (*/}
                {/*      <div paragraph>*/}
                {/*        {FormDataOpper?.deal_notes?.helper_text}*/}
                {/*      </div>*/}
                {/*    )}*/}
                {/*  </div>*/}
                {/*)}*/}
                <div className="menu">
                  <h4 className="ai-credits">
                    AI Credits: {this.props.opportunity_data.credits}
                  </h4>
                  <ul className="r_tab_nav">
                    <li>
                      <button
                        id="tab_btn_0"
                        type="button"
                        data-target="tab0"
                        className="active"
                        onClick={() => changeTab("tab0")}
                      >
                        <Info style={{ marginRight: "4px" }} /> Data Lake
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_1"
                        type="button"
                        data-target="tab1"
                        className=""
                        onClick={() => changeTab("tab1")}
                      >
                        <Assignment style={{ marginRight: "4px" }} /> Reports
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_2"
                        type="button"
                        data-target="tab2"
                        className=""
                        onClick={() => changeTab("tab2")}
                      >
                        <RateReview style={{ marginRight: "4px" }} /> Review
                        Deal
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_3"
                        type="button"
                        data-target="tab3"
                        className=""
                        onClick={() => changeTab("tab3")}
                      >
                        <Chat style={{ marginRight: "4px" }} />
                        Diligence Chat
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_4"
                        type="button"
                        data-target="tab4"
                        className=""
                        onClick={() => changeTab("tab4")}
                      >
                        <PersonAdd style={{ marginRight: "4px" }} />
                        Invite Founder
                      </button>
                    </li>
                    <li>
                      <button
                        id="tab_btn_5"
                        type="button"
                        data-target="tab5"
                        className=""
                        onClick={(event) =>
                          this.props.flagged_popup(
                            event,
                            this.props.selected_company[0],
                          )
                        }
                      >
                        <DeleteForever style={{ marginRight: "4px" }} /> Delete
                        Deal
                      </button>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="info_review_card_middle">
                <div className="review_tabs_wrap">
                  {/*<ul className="r_tab_nav">*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_0"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab0"*/}
                  {/*      className="active"*/}
                  {/*      onClick={() => changeTab("tab0")}*/}
                  {/*    >*/}
                  {/*      Deal Info*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_1"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab1"*/}
                  {/*      className=""*/}
                  {/*      onClick={() => changeTab("tab1")}*/}
                  {/*    >*/}
                  {/*      Application Info*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_2"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab2"*/}
                  {/*      className=""*/}
                  {/*      onClick={() => changeTab("tab2")}*/}
                  {/*    >*/}
                  {/*      Review Deal*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_3"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab3"*/}
                  {/*      className=""*/}
                  {/*      onClick={() => changeTab("tab3")}*/}
                  {/*    >*/}
                  {/*      Startup Score*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_4"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab4"*/}
                  {/*      className=""*/}
                  {/*      onClick={() => changeTab("tab4")}*/}
                  {/*    >*/}
                  {/*      DiligenceGPT*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_5"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab5"*/}
                  {/*      className=""*/}
                  {/*      onClick={() => changeTab("tab5")}*/}
                  {/*    >*/}
                  {/*      Reports*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*  <li>*/}
                  {/*    <button*/}
                  {/*      id="tab_btn_6"*/}
                  {/*      type="button"*/}
                  {/*      data-target="tab6"*/}
                  {/*      className=""*/}
                  {/*    >*/}
                  {/*      <i*/}
                  {/*        className="material-icons cancel-icon"*/}
                  {/*        style={{*/}
                  {/*          fontSize: "15px",*/}
                  {/*          margin: 0,*/}
                  {/*        }}*/}
                  {/*        onClick={(event) =>*/}
                  {/*          this.props.flagged_popup(*/}
                  {/*            event,*/}
                  {/*            this.props.selected_company[0],*/}
                  {/*          )*/}
                  {/*        }*/}
                  {/*      >*/}
                  {/*        delete*/}
                  {/*      </i>*/}
                  {/*    </button>*/}
                  {/*  </li>*/}
                  {/*</ul>*/}
                  <div className="r_tab_content active" id="tab0">
                    <DealInfo
                      updateCompanyData={this.props.updateCompanyData}
                      getFiles={this.getFiles}
                      deleteFile={this.deleteFile}
                      companyInfo={this.props.selected_company[0]}
                      applicationObject={this.state.applicationObject}
                      files={this.state.files}
                      opportunity_data={this.props.opportunity_data}
                      handleCreditChange={this.handleCreditChange}
                      user={this.props.user}
                    />
                    {/*<div
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className="__content info_review_card_right"
                    >
                      <div className="w-100">
                        <div className="Deal-Info">
                          <h4>Deal Info</h4>
                          <p className="text-grey text-sm">
                            Business details and application
                          </p>
                          <div className=""></div>
                          <table className="table-fixed w-100 ">
                            <tbody>
                              <tr className="border-bottom border-gray-100 border-t">
                                <td className="py-4 font-bold text-sm">
                                  Business Name
                                </td>
                                <td className="py-4 text-sm">
                                  {this.props.selected_company[0].company_name}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 py-4 font-bold text-sm">
                                  Website
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0]
                                    .company_website &&
                                    this.props.selected_company[0]
                                      .company_website.length !== 0 && (
                                      <a
                                        href={
                                          this.props.selected_company[0].company_website.includes(
                                            "http",
                                          )
                                            ? this.props.selected_company[0]
                                                .company_website
                                            : `http://${this.props.selected_company[0].company_website}`
                                        }
                                        target="_blank"
                                      >
                                        {
                                          this.props.selected_company[0]
                                            .company_website
                                        }
                                      </a>
                                    )}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 py-4 font-bold text-sm">
                                  Location
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0]
                                    .company_website &&
                                    this.props.selected_company[0]
                                      .company_website.length !== 0 &&
                                    this.props.selected_company[0]
                                      .formatted_address &&
                                    this.props.selected_company[0]
                                      .formatted_address.length !== 0}
                                  {this.props.selected_company[0]
                                    .formatted_address &&
                                    this.props.selected_company[0]
                                      .formatted_address.length !== 0 && (
                                      <p
                                        className="text-sm"
                                        style={{ display: "inline" }}
                                      >
                                        {this.props.selected_company[0].city},{" "}
                                        {this.props.selected_company[0].country}
                                      </p>
                                    )}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-4 font-bold text-sm align-top">
                                  Description
                                </td>
                                <td className="text-sm py-4">
                                  {this.props.selected_company[0].short_bio}
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-2 font-bold text-sm align-top py-4">
                                  Business Models
                                </td>
                                <td className="text-sm">
                                  <Chip
                                    variant="outlined"
                                    className={classnames(
                                      "exploreTag",
                                      "blueHl",
                                    )}
                                    color={"primary"}
                                    size="small"
                                    label={
                                      this.props.selected_company[0]
                                        .business_model
                                    }
                                  />
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t">
                                <td className="w-20 font-bold text-sm align-top py-4">
                                  Industries Disrupted
                                </td>
                                <td className="text-sm py-4">
                                  <div className="allTags">
                                    {this.props.selected_company[0].industry.map(
                                      (val) => (
                                        <Chip
                                          variant="outlined"
                                          className={classnames(
                                            "exploreTag",
                                            "greenHl",
                                          )}
                                          color={"primary"}
                                          size="small"
                                          label={val}
                                        />
                                      ),
                                    )}
                                  </div>
                                </td>
                              </tr>
                              <tr className="border-bottom border-gray border-t p-3">
                                <td className="w-20 py-2 font-bold text-sm align-top py-4">
                                  Data Room
                                </td>
                                <td className="text-sm py-4 mb-4">
                                  {this.state.files &&
                                  this.state.files.length > 0
                                    ? this.state.files.map((file, index) => {
                                        const displayFileType =
                                          Object.keys(file_type_mapping).find(
                                            (key) =>
                                              file_type_mapping[key] ===
                                              file.file_type,
                                          ) || file.file_type; // Default to file.file_type if not found in the mapping

                                        return (
                                          <div
                                            key={index}
                                            className="documents p-3"
                                          >
                                            <i
                                              className="fa fa-paperclip text-gray"
                                              aria-hidden="true"
                                            ></i>
                                            <span>{displayFileType}</span>
                                            <span
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                gap: "5px",
                                                paddingLeft: "10px",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <a
                                                href={file.file_url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                <CloudDownload />
                                              </a>
                                              <Visibility
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.handleVisibilityClick(
                                                    file.file_url,
                                                  )
                                                }
                                              />
                                              <Delete
                                                style={{ cursor: "pointer" }}
                                                onClick={() =>
                                                  this.deleteFile(
                                                    file.file_type,
                                                  )
                                                }
                                              />
                                            </span>
                                          </div>
                                        );
                                      })
                                    : null}

                                  <FormControl
                                    type="file"
                                    data-file-type="pitchdeck"
                                    placeholder="pitchdeck"
                                    accept=".pdf,.jpg,.jpeg,.png,.doc,.docx"
                                    name="pitchdeck"
                                    onChange={this.handleFileUpload}
                                    className="form-control mb-3"
                                  />
                                  <Button
                                    onClick={() =>
                                      this.handleFileSubmit("pitchdeckDetails")
                                    }
                                    className="mt-2 btn btn-md"
                                    variant="contained"
                                    color="primary"
                                    disabled={this.state.UploadingDocument}
                                  >
                                    {this.state.UploadingDocument
                                      ? "Uploading Document..."
                                      : "Upload Document"}
                                  </Button>
                                </td>
                              </tr>
                              <td className="w-20 py-2 font-bold text-sm align-top py-4">
                                Upload Documents
                              </td>
                              <td className="text-sm py-4 mb-4">
                                <MultipleDocumentUpload
                                  selected_company={this.props.selected_company}
                                  getFiles={this.getFiles}
                                />
                              </td>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>*/}
                  </div>

                  {/*<div className="r_tab_content" id="tab1">
                    <div
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      className="__content info_review_card_right"
                    >
                      <div className="w-100">
                        <div>
                          <h4>Application Info</h4>
                          <p className="text-grey text-sm">
                            Application submission details
                          </p>
                          <table className="table-fixed w-100">
                            <tbody>
                              {Object.keys(this.state.applicationObject)
                                .filter(
                                  (key) =>
                                    this.state.applicationObject[key].Answer &&
                                    this.state.applicationObject[
                                      key
                                    ].Answer.trim() !== "",
                                )
                                .map((key) => (
                                  <tr
                                    className="border-bottom border-gray border-t"
                                    key={key}
                                  >
                                    <td className="py-4 font-bold text-sm">
                                      {this.state.applicationObject[key].Title}
                                    </td>
                                    <td className="py-4 text-sm">
                                      {this.state.applicationObject[key].Answer}
                                    </td>
                                  </tr>
                                ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>*/}

                  <div className="r_tab_content" id="tab1">
                    <ReportsMain
                      updateCompanyData={this.props.updateCompanyData}
                      opportunityInfo={this.props.opportunity_data}
                      companyInfo={this.props.selected_company[0]}
                      applicationObject={this.state.applicationObject}
                      handleCreditChange={this.handleCreditChange}
                      user={this.props.user}
                    />
                    {/*<div className="px-3">*/}
                    {/*  <h4>Startup Score</h4>*/}
                    {/*  <p className="text-grey text-sm">*/}
                    {/*    Generate a Startup Score using the patent pending*/}
                    {/*    StartupFuel Scoring DiligenceGPT Model.*/}
                    {/*  </p>*/}
                    {/*  <>*/}
                    {/*    <div className="flex justify-between">*/}
                    {/*      {" "}*/}
                    {/*      /!* Adjusted for space-between *!/*/}
                    {/*      <div className="mr-4">*/}
                    {/*        {" "}*/}
                    {/*        /!* Add right margin to the first button *!/*/}
                    {/*        <button*/}
                    {/*          onClick={this.handleStartupScore}*/}
                    {/*          disabled={this.state.isSummarizing}*/}
                    {/*          style={{*/}
                    {/*            backgroundColor: this.state.isSummarizing*/}
                    {/*              ? "#ccc"*/}
                    {/*              : "#007bff",*/}
                    {/*            color: "#fff",*/}
                    {/*            border: "none",*/}
                    {/*            padding: "10px 20px",*/}
                    {/*            fontSize: "16px",*/}
                    {/*            borderRadius: "5px",*/}
                    {/*            cursor: this.state.isSummarizing*/}
                    {/*              ? "not-allowed"*/}
                    {/*              : "pointer",*/}
                    {/*            opacity: this.state.isSummarizing ? 0.6 : 1,*/}
                    {/*          }}*/}
                    {/*        >*/}
                    {/*          {this.state.isSummarizing*/}
                    {/*            ? "Generating..."*/}
                    {/*            : "Generate Startup Score"}*/}
                    {/*        </button>*/}
                    {/*      </div>*/}
                    {/*    </div>*/}
                    {/*    {this.renderScoreDetails()}*/}
                    {/*  </>*/}
                    {/*</div>*/}
                  </div>

                  <div
                    className="info_review_card_left r_tab_content"
                    id="tab2"
                  >
                    <div className="row justify-content-md-center">
                      <div className="tearsheet">
                        {/* Company cover image */}
                        {this.props.selected_company[0]
                          .pitch_comp_video_link !== undefined &&
                        this.props.opportunity_data.enter_pitch_video ? (
                          <iframe
                            src={
                              this.props.selected_company[0]
                                .pitch_comp_video_link
                            }
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        ) : this.props.selected_company[0]
                            .background_video_link !== undefined &&
                          this.props.selected_company[0]
                            .background_video_link !== null &&
                          this.props.selected_company[0].background_video_link
                            .length > 0 ? (
                          <iframe
                            src={
                              this.props.selected_company[0]
                                .background_video_link
                            }
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        ) : (
                          <img
                            src={this.props.getAvatarUrl(
                              this.props.selected_company[0].company_files,
                              "company background",
                              this.props.selected_company[0]
                                .temp_background_url,
                            )}
                            alt="hero image"
                          />
                        )}
                        {/* Company Profile Image */}
                        <div className="__company-image-wrapper">
                          <div>
                            <img
                              style={{ width: "100%", height: "100%" }}
                              src={this.props.getAvatarUrl(
                                this.props.selected_company[0].company_files,
                                "company avatar",
                              )}
                            ></img>
                          </div>
                        </div>
                        <div className="text-center">
                          <h4 className="__company-name">
                            <a
                              href={
                                "/profile?company_id=" +
                                this.props.selected_company[0].applicant_company
                              }
                              target="_blank"
                            >
                              {this.props.selected_company[0].company_name}
                            </a>
                          </h4>
                        </div>
                        <p className="text-center mb-0">
                          <Typography
                            variant="p"
                            color="textPrimary"
                            class="my-3"
                          >
                            {this.props.selected_company[0].company_website &&
                              this.props.selected_company[0].company_website
                                .length !== 0 && (
                                <a
                                  href={
                                    this.props.selected_company[0].company_website.includes(
                                      "http",
                                    )
                                      ? this.props.selected_company[0]
                                          .company_website
                                      : `http://${this.props.selected_company[0].company_website}`
                                  }
                                  target="_blank"
                                >
                                  {
                                    this.props.selected_company[0]
                                      .company_website
                                  }
                                </a>
                              )}
                            {this.props.selected_company[0].company_website &&
                              this.props.selected_company[0].company_website
                                .length !== 0 &&
                              this.props.selected_company[0]
                                .formatted_address &&
                              this.props.selected_company[0].formatted_address
                                .length !== 0 && <span> | </span>}
                            {this.props.selected_company[0].formatted_address &&
                              this.props.selected_company[0].formatted_address
                                .length !== 0 && (
                                <p style={{ display: "inline" }}>
                                  {this.props.selected_company[0].city},
                                  {this.props.selected_company[0].province},
                                  {this.props.selected_company[0].country}
                                </p>
                              )}
                          </Typography>
                        </p>
                        <div className="exploreCardTags mb-4">
                          <div className="allTags text-center">
                            <Chip
                              variant="outlined"
                              className={classnames("exploreTag", "")}
                              color={"default"}
                              size="small"
                              label={this.props.selected_company[0].country}
                            />
                            {this.props.selected_company[0].industry.map(
                              (val) => (
                                <Chip
                                  variant="outlined"
                                  className={classnames(
                                    "exploreTag",
                                    "greenHl",
                                  )}
                                  color={"primary"}
                                  size="small"
                                  label={val}
                                />
                              ),
                            )}
                          </div>
                        </div>

                        {this.state.oppotunitydata &&
                          typeof this.state.oppotunitydata.opportunity_form ===
                            "object" &&
                          Object.keys(
                            this.state.oppotunitydata.opportunity_form,
                          ).map(
                            (opp_form_key, i) =>
                              this.state.oppotunitydata.opportunity_form[
                                opp_form_key
                              ]["display"] === true && (
                                <div className="mt-4">
                                  <h6>
                                    {
                                      this.state.oppotunitydata
                                        .opportunity_form[opp_form_key]["title"]
                                    }
                                  </h6>
                                  <p>
                                    {
                                      this.props.selected_company[0][
                                        opp_form_key
                                      ]
                                    }
                                  </p>
                                </div>
                              ),
                          )}
                        <div>
                          <Typography>Contact Info</Typography>
                          <p>
                            <span style={{ fontStyle: "italic" }}>Name: </span>{" "}
                            {this.props.selected_company[0].owner.first_name}{" "}
                            {this.props.selected_company[0].owner.last_name}{" "}
                          </p>
                          <p>
                            <span style={{ fontStyle: "italic" }}>Email: </span>{" "}
                            {this.props.selected_company[0].owner.email}{" "}
                          </p>
                          <p>
                            <span style={{ fontStyle: "italic" }}>#: </span>{" "}
                            {this.props.contact_phone}{" "}
                          </p>
                        </div>
                      </div>
                      <ChatLayout
                        companyInfo={this.props.selected_company[0]}
                        opportunity_data={this.props.opportunity_data}
                        updateCompanyData={this.props.updateCompanyData}
                        applicationObject={this.state.applicationObject}
                        handleCreditChange={this.handleCreditChange}
                        user={this.props.user}
                      >
                        <div className="px-3 review-tab-main-view">
                          <h4>Review Deal</h4>
                          <p className="text-grey text-sm">
                            Leave a review and comment below
                          </p>
                          {this.state.url.pathname === "/applicant_room_top" ? (
                            <Fragment>
                              <div className="__star-ratings">
                                <h5 className="__company-name">
                                  <a
                                    href={
                                      "/profile?company_id=" +
                                      this.props.selected_company[0]
                                        .applicant_company
                                    }
                                    target="_blank"
                                  >
                                    {
                                      this.props.selected_company[0]
                                        .company_name
                                    }
                                  </a>
                                </h5>
                                <div className="__rating justify-content-center overallrating">
                                  <div className="__reviews">
                                    <div className="__graphical-reviews">
                                      <StarRatings
                                        className="__stars"
                                        rating={this.props.totalOverall}
                                        starRatedColor="#C49733"
                                        numberOfStars={10}
                                        starDimension={20}
                                        starSpacing="0"
                                      />
                                    </div>
                                    <div className="__number-reviews overallscore">
                                      {this.props.totalOverall.toFixed(1)}
                                    </div>
                                    <hr />
                                    {/* <div className='__text-reviews'> {this.props.reviews.length} review(s).</div> */}
                                  </div>
                                </div>
                                <br />
                                <Rating
                                  starDimension={40}
                                  title="Idea"
                                  rating={this.props.totalIdea}
                                />
                                <Rating
                                  starDimension={40}
                                  title="Tech"
                                  rating={this.props.totalTechnology}
                                />
                                <Rating
                                  starDimension={40}
                                  title="Team"
                                  rating={this.props.totalTeam}
                                />
                                <Rating
                                  starDimension={40}
                                  title="Marketing"
                                  rating={this.props.totalMarketing}
                                />
                                {this.props.opportunity_data
                                  .additional_review_options &&
                                  this.props.opportunity_data
                                    .additional_review_criteria !== undefined &&
                                  this.props.opportunity_data
                                    .additional_review_criteria !== null &&
                                  this.props.opportunity_data
                                    .additional_review_criteria.length > 0 &&
                                  this.props.opportunity_data.additional_review_criteria.map(
                                    (criteria) => (
                                      <Rating
                                        starDimension={40}
                                        title={criteria}
                                        rating={this.props.totalAdditionalReviewCriteria(
                                          criteria,
                                        )}
                                      />
                                    ),
                                  )}
                              </div>
                              {/* Write Review
                                  <div className='__write-review-button' style={{width:"unset"}} onClick={this.props.handleToggle}>
                                      <i class="far fa-edit"></i>
                                      {hasReviewed ? 'Edit The' : 'Write A'} Review For This Company!
                                  </div>
                                  */}
                              <div className="comments">
                                {this.props.reviews.map((e, i) => {
                                  return (
                                    <Comment
                                      data={e}
                                      getAvatarUrl={this.getAvatarUrl}
                                    />
                                  );
                                })}
                              </div>
                            </Fragment>
                          ) : (
                            <Fragment>
                              <br />
                              <div className="w-100">
                                <Rating
                                  starDimension={40}
                                  title="Idea | Problem & Solution"
                                  name="idea"
                                  rating={this.props.idea}
                                  changeRating={this.props.changeRating}
                                />
                                <TextField
                                  id="filled-multiline-flexible"
                                  label=""
                                  multiline
                                  placeholder="Provide rating and feedback on the idea of the company. Review the problem they are solving, the solution they are providing, and the market opportunity."
                                  rows="4"
                                  value={this.props.idea_comment}
                                  onChange={this.props.handleChange}
                                  name="idea_comment"
                                  margin="normal"
                                  fullWidth
                                />
                                <Rating
                                  starDimension={40}
                                  title="Product/Service  "
                                  name="technology"
                                  rating={this.props.technology}
                                  changeRating={this.props.changeRating}
                                />
                                <TextField
                                  id="filled-multiline-flexible"
                                  label=""
                                  multiline
                                  placeholder="Provide rating and feedback on the product/service of the company. Review the product's features, the product's uniqueness, and the product's scalability."
                                  rows="4"
                                  value={this.props.technology_comment}
                                  onChange={this.props.handleChange}
                                  name="technology_comment"
                                  margin="normal"
                                  fullWidth
                                />
                                <Rating
                                  starDimension={40}
                                  title="Team | Human Capital & Talent"
                                  name="team"
                                  rating={this.props.team}
                                  changeRating={this.props.changeRating}
                                />
                                <TextField
                                  id="filled-multiline-flexible"
                                  label=""
                                  multiline
                                  placeholder="Provide rating and feedback on the team of the company. Review the team's experience, the team's ability to execute, and the team's ability to scale."
                                  rows="4"
                                  value={this.props.team_comment}
                                  onChange={this.props.handleChange}
                                  name="team_comment"
                                  margin="normal"
                                  fullWidth
                                />
                                <Rating
                                  starDimension={40}
                                  title="Marketing | Customer Acquisition & Gotomarket Strategy"
                                  name="marketing"
                                  rating={this.props.marketing}
                                  changeRating={this.props.changeRating}
                                />
                                <TextField
                                  id="filled-multiline-flexible"
                                  label=""
                                  multiline
                                  placeholder="Provide rating and feedback on the marketing of the company. Review the company's marketing strategy, the company's ability to acquire customers, and the company's ability to retain customers."
                                  rows="4"
                                  value={this.props.marketing_comment}
                                  onChange={this.props.handleChange}
                                  name="marketing_comment"
                                  margin="normal"
                                  fullWidth
                                />
                                {this.props.opportunity_data
                                  .additional_review_options &&
                                  this.props.opportunity_data
                                    .additional_review_criteria !== undefined &&
                                  this.props.opportunity_data
                                    .additional_review_criteria !== null &&
                                  this.props.opportunity_data
                                    .additional_review_criteria.length > 0 &&
                                  this.props.opportunity_data.additional_review_criteria.map(
                                    (criteria) => (
                                      <>
                                        <Rating
                                          starDimension={40}
                                          rating={
                                            this.props
                                              .additional_review_criteria !==
                                              undefined &&
                                            this.props
                                              .additional_review_criteria[
                                              criteria
                                            ] !== undefined
                                              ? this.props
                                                  .additional_review_criteria[
                                                  criteria
                                                ]
                                              : 0
                                          }
                                          changeRating={
                                            this.props
                                              .handleChangeAdditionalRating
                                          }
                                          name={criteria}
                                          title={criteria}
                                        />
                                        <TextField
                                          id="filled-multiline-flexible"
                                          label=""
                                          multiline
                                          placeholder="Provide a rating and feedback on the company's regarding this custom criteria"
                                          rows="4"
                                          value={
                                            this.props
                                              .additional_review_criteria_comment !==
                                              undefined &&
                                            this.props
                                              .additional_review_criteria_comment[
                                              criteria
                                            ] !== undefined
                                              ? this.props
                                                  .additional_review_criteria_comment[
                                                  criteria
                                                ]
                                              : ""
                                          }
                                          onChange={
                                            this.props
                                              .handleChangeAdditionalRatingComment
                                          }
                                          name={criteria}
                                          margin="normal"
                                          fullWidth
                                        />
                                      </>
                                    ),
                                  )}{" "}
                                <hr />
                                {/* <TextField
                                              id="filled-multiline-flexible"
                                              label="Comment"
                                              multiline
                                              placeholder="Only visible to the other judges, this will not be seen by the applicant."
                                              rows="4"
                                              value={this.props.comment}
                                              onChange={this.props.handleChange}
                                              name="comment"
                                              margin="normal"
                                              variant="filled"
                                              fullWidth
                                          /> */}
                                <TextField
                                  id="filled-multiline-flexible"
                                  label="Feedback"
                                  multiline
                                  rows="4"
                                  placeholder="Information written here will be viewable by the deal owner and is a way to provide feedback for improvement."
                                  value={this.props.feedback}
                                  onChange={this.props.handleChange}
                                  name="feedback"
                                  margin="normal"
                                  fullWidth
                                />
                                <Rating
                                  starDimension={40}
                                  className="__total-ratings"
                                  title="Overall rating"
                                  name="overall"
                                  rating={
                                    this.props.pitchOverallRating !==
                                      undefined &&
                                    this.props.pitchOverallRating !== null
                                      ? this.props.pitchOverallRating
                                      : this.props.overallRating
                                  }
                                />
                                <Button
                                  onClick={
                                    hasReviewed
                                      ? this.props.editReviewSubmit
                                      : this.props.reviewSubmit
                                  }
                                  variant="outlined"
                                  color="primary"
                                  className="__submit-review-btn"
                                  fullWidth
                                >
                                  Submit Review
                                </Button>
                              </div>
                              <div></div>
                            </Fragment>
                          )}
                        </div>
                      </ChatLayout>
                    </div>
                  </div>

                  {/*<div className="r_tab_content tabchatgpt-main" id="tab3">
                    <div className="px-3">
                      <h4>DiligenceGPT</h4>
                      <p className="text-grey text-sm">
                        Ask questions directly to the data room, and deal info
                      </p>
                      <form
                        className="r_faq_form"
                        onSubmit={
                          editingQuestionId
                            ? this.handleUpdateQuestion
                            : this.handleAddQuestion
                        }
                      >
                        <div className="r_faq_question">
                          <input
                            type="text"
                            value={question}
                            onChange={this.handleQuestionChange}
                            placeholder="Ask DiligenceGPT questions about this deal..."
                            class="input-field text-sm"
                          />
                          <button
                            type="submit"
                            data-question={question}
                            class="btn"
                            onClick={(event) =>
                              this.handleSummarizeNew(
                                event,
                                questions.length + 1,
                              )
                            }
                            disabled={!question}
                          >
                            <i class="fas fa-arrow-right"></i>
                          </button>
                        </div>

                        <div className="form-chatgpt-layout">
                          <div className="faq_answer">
                             {console.log("questions 1492",questions)}
                            {questions.map((question) => (
                              <div className="r_faq_item" key={question.id}>
                                <div className="faq_question">
                                  <div className="question-main-wraper-m">
                                    <div className="question-main-wraper-m-u">
                                      <p>
                                        {this.props?.selected_company[0]?.company_name?.charAt(
                                          0,
                                        )}
                                      </p>
                                    </div>
                                    <div className="question-main-wraper-m-t">
                                      <h4>You</h4>
                                      <p>{question.question}</p>
                                    </div>
                                  </div>
                                  {question.id === editingQuestionId ? (
                                    <div className="faq_edit_section">
                                      <input
                                        className="border-none"
                                        type="text"
                                        value={question.question}
                                        onChange={(event) =>
                                          this.handleQuestionChange(
                                            event,
                                            question.id,
                                          )
                                        }
                                        placeholder="Edit question..."
                                      />
                                      <input
                                        className="border-none"
                                        type="text"
                                        value={answer}
                                        onChange={(event) =>
                                          this.handleAnswerChange(
                                            event,
                                            question.id,
                                          )
                                        }
                                        placeholder="Edit answer..."
                                      />
                                    </div>
                                  ) : (
                                    <div className="answer-main-wraper-a">
                                      <div className="answer-main-wraper-u">
                                        <img
                                          src={require("../../../../img/favicon.png")}
                                        />
                                      </div>
                                      <div className="answer-main-wraper-t">
                                        <h4>DiligenceGPT</h4>
                                        <p>
                                          {question.answer
                                            ? this.renderGPTAnimation(
                                                question.answer,
                                                question.id,
                                              )
                                            : "Waiting..."}
                                        </p>
                                        <div className="delete-question-m">
                                          {question.answer && (
                                            <p
                                              onClick={() =>
                                                this.handleDeleteQuestion(
                                                  question.id,
                                                )
                                              }
                                            >
                                              <i class="fas fa-trash"></i>
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>*/}

                  <div className="r_tab_content tabchatgpt-main" id="tab3">
                    <ChatComponent
                      companyInfo={this.props.selected_company[0]}
                      opportunityInfo={this.props.opportunity_data}
                      updateCompanyData={this.props.updateCompanyData}
                      applicationObject={this.state.applicationObject}
                      handleCreditChange={this.handleCreditChange}
                      user={this.props.user}
                    />
                  </div>

                  <div className="r_tab_content" id="tab4">
                    <InviteFounder
                      companyInfo={this.props.selected_company[0]}
                    />
                  </div>

                  {/*<div className="r_tab_content" id="tab4">*/}
                  {/*  <div className="px-3">*/}
                  {/*    <h4>Reports</h4>*/}
                  {/*    <p className="text-grey text-sm">*/}
                  {/*      Generate, edit & download deal reports*/}
                  {/*    </p>*/}
                  {/*    <Tearsheet*/}
                  {/*      applicationInfo={this.state.applicationObject}*/}
                  {/*      company={this.props.selected_company[0]}*/}
                  {/*      handleCreditChange={this.handleCreditChange}*/}
                  {/*      opportunity_data={this.props.opportunity_data}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
