import React, { useState, useEffect, useRef } from "react";
import "./reports.css";
import { TypeAnimation } from "react-type-animation";
import { ExpandMore, ExpandLess, Delete } from "@material-ui/icons";
import html2pdf from "html2pdf.js";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import LoadingButton from "../../loadingButton";

export default function Summary({
  companyInfo,
  applicationObject,
  updateCompanyData,
  handleCreditChange,
  opportunityInfo,
}) {
  const [summaries, setSummaries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNewlyGenerated, setIsNewlyGenerated] = useState(false);
  const [animationComplete, setAnimationComplete] = useState({});
  const [expandedSummaries, setExpandedSummaries] = useState({});
  const [downloadingIndex, setDownloadingIndex] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(null);
  const [generationStep, setGenerationStep] = useState("");
  const [progress, setProgress] = useState(0);
  const pdfRefs = useRef({});

  useEffect(() => {
    if (companyInfo.aiGenerations?.summaries) {
      setSummaries([...companyInfo.aiGenerations.summaries].reverse());
    }
  }, [companyInfo]);

  const sections = ["Idea", "Tech", "Team", "Marketing", "Traction"];

  const simulateProgress = async () => {
    // Extracting phase (3 seconds)
    setGenerationStep("Extracting");
    for (let i = 0; i <= 30; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 100));
    }
    
    // Summarizing phase (5 seconds) 
    setGenerationStep("Summarizing");
    for (let i = 31; i <= 80; i++) {
      setProgress(i);
      await new Promise(r => setTimeout(r, 62.5));
    }

    // Outputting phase (remaining time)
    setGenerationStep("Outputting");
  };

  const handleDelete = async (id) => {
    setIsDeleting(true);
    try {
      const response = await fetch(`/delete_ai_generation/${id}`, {
        method: "DELETE",
      });

      if (!response.ok) {
        throw new Error("Failed to delete summary");
      }

      // Update local state
      setSummaries((summaries) =>
        summaries.filter((summary) => summary._id !== id),
      );

      // Update company data
      const updatedSummaries = companyInfo.aiGenerations.summaries.filter(
        (summary) => summary._id !== id,
      );

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          summaries: updatedSummaries,
        },
      };

      updateCompanyData(companyInfo.applicant_company, updates);
    } catch (error) {
      console.error("Error deleting summary:", error);
      setError("Failed to delete summary. Please try again.");
    }
    setShowDeleteConfirm(null);
    setIsDeleting(false);
  };

  const handleSummarize = async () => {
    const companyId = companyInfo.applicant_company;
    const opportunityId = opportunityInfo._id;

    if (!applicationObject) {
      setError("Application Info is missing.");
      return;
    }

    if (opportunityInfo.credits < 1) {
      setError("You do not have enough credits to generate a deal brief.");
      return;
    }

    setLoading(true);
    setError(null);
    setProgress(0);
    simulateProgress();

    try {
      const requestBody = {};
      const hasValidAnswers =
        applicationObject &&
        Object.values(applicationObject).some(
          (answer) => typeof answer === "string" && answer.trim() !== "",
        );

      if (hasValidAnswers) {
        requestBody.applicationObject = applicationObject;
      }

      const response = await fetch(
        `/fetch_company_summary?company_id=${companyId}&opportunity_id=${opportunityId}`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(requestBody),
        },
      );

      if (!response.ok) {
        if (response.status === 404) {
          setError(
            "Pitch deck or necessary information is missing. Please upload a pitch deck.",
          );
        } else {
          setError(
            "Due to high network load, we were unable to generate the summary. Please try again later.",
          );
        }
        throw new Error("Failed to fetch deal brief.");
      }

      const data = await response.json();

      handleCreditChange(-1);

      const newSummary = {
        summary: data.summary.summary,
        created_on: data.summary.created_on,
        credits_used: data.summary.credits_used,
        _id: data.summary._id,
      };

      const updates = {
        aiGenerations: {
          ...companyInfo.aiGenerations,
          summaries: [
            newSummary,
            ...(companyInfo.aiGenerations?.summaries || []),
          ],
        },
      };

      updateCompanyData(companyId, updates);
      setSummaries((prevSummaries) => [newSummary, ...prevSummaries]);
      setIsNewlyGenerated(true);
      setAnimationComplete({});
      setExpandedSummaries((prev) => ({ ...prev, 0: true }));
      setProgress(100);
    } catch (error) {
      setError(
        "Due to high network load, we were unable to fetch the summary. Please try again later.",
      );
    } finally {
      setLoading(false);
      setGenerationStep("");
      setProgress(0);
    }
  };

  const handleAnimationComplete = (section, summaryIndex) => {
    setAnimationComplete((prevState) => ({
      ...prevState,
      [`${summaryIndex}-${section}`]: true,
    }));

    const allComplete = Object.values({
      ...animationComplete,
      [`${summaryIndex}-${section}`]: true,
    }).every((value) => value);

    if (allComplete) {
      setIsNewlyGenerated(false);
    }
  };

  const renderTypingAnimation = (text, section, summaryIndex) => (
    <TypeAnimation
      sequence={[text, () => handleAnimationComplete(section, summaryIndex)]}
      wrapper="span"
      cursor={true}
      speed={85}
      repeat={0}
      style={{ whiteSpace: "pre-line" }}
    />
  );

  const toggleSummaryExpansion = (index) => {
    setExpandedSummaries((prev) => ({ ...prev, [index]: !prev[index] }));
  };

  const downloadPdf = async (summaryIndex) => {
    setDownloadingIndex(summaryIndex);
    if (!pdfRefs.current[summaryIndex]) {
      toggleSummaryExpansion(summaryIndex);
      await new Promise((resolve) => setTimeout(resolve, 300));
    }
    await initiateDownload(summaryIndex);
    setDownloadingIndex(null);
  };

  const initiateDownload = async (summaryIndex) => {
    const element = pdfRefs.current[summaryIndex];
    if (!element) return;
    const heightPx = element.scrollHeight;
    const heightCM = heightPx / 35.35;

    const options = {
      filename: companyInfo.company_name + " Deal Brief | StartupFuel.pdf",
      html2canvas: { dpi: 192, letterRendering: true, scale: 2 },
      jsPDF: {
        orientation: "portrait",
        unit: "cm",
        format: [heightCM, 29.7],
      },
    };

    try {
      await html2pdf().from(element).set(options).save();
    } catch (error) {
      console.error("Error downloading PDF:", error);
      setError("Failed to download PDF. Please try again.");
    }
  };

  const renderSummaryDetails = (summaryObj, index) => {
    if (!summaryObj || Object.keys(summaryObj.summary).length === 0)
      return null;

    return (
      <div className="report-container" key={index} id={`summary-${index}`}>
        <div
          className="overall-summary"
          onClick={() => toggleSummaryExpansion(index)}
        >
          <h2 className="overall-score">
            Deal brief {summaries.length - index}:{" "}
            {expandedSummaries[index] ? <ExpandLess /> : <ExpandMore />}
          </h2>
          <div className="score-details-right">
            <p className="credits-used">
              Credits Used: {summaryObj.credits_used}
            </p>
            <p className="created-on">
              Date Created:{" "}
              {new Date(summaryObj.created_on).toLocaleDateString()}
            </p>
            <div className="score-button-container">
              <LoadingButton
                className="button button-secondary"
                onClick={(e) => {
                  e.stopPropagation();
                  downloadPdf(index);
                }}
                loading={downloadingIndex === index}
                text={
                  downloadingIndex === index ? "Downloading..." : "Download PDF"
                }
              />
              <button
                className="button button-danger ml-2"
                onClick={(e) => {
                  e.stopPropagation();
                  setShowDeleteConfirm(summaryObj._id);
                }}
              >
                <Delete />
              </button>
            </div>
            {showDeleteConfirm === summaryObj._id && (
              <div style={{ textAlign: "left" }}>
                <p>Are you sure you want to delete this summary?</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <LoadingButton
                    className="button button-danger mr-2"
                    onClick={() => handleDelete(summaryObj._id)}
                    loading={isDeleting}
                    text="Delete"
                  />
                  <button
                    className="button button-secondary"
                    onClick={() => setShowDeleteConfirm(null)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>

        {expandedSummaries[index] && (
          <div
            id={`summary-content-${index}`}
            ref={(el) => (pdfRefs.current[index] = el)}
          >
            <PdfHeader
              company={companyInfo}
              created_on={summaryObj.created_on}
              docType="StartupDeal Brief"
            />
            <div style={{ padding: "20px" }}>
              {sections.map((section) => (
                <div key={section} className="category-item">
                  <h3 className="category-title">{section}</h3>
                  <p className="summary-text">
                    {isNewlyGenerated && index === 0
                      ? renderTypingAnimation(
                          summaryObj.summary[section] ||
                            `No deal brief available for ${section}`,
                          section,
                          index,
                        )
                      : summaryObj.summary[section] ||
                        `No deal brief available for ${section}`}
                  </p>
                </div>
              ))}
            </div>
            <PdfFooter />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="px-3">
      <h4>Deal Summary</h4>
      <p className="text-grey text-sm">
        Generate a Deal Brief of the Deal using the patent pending Deal
        Summarization DiligenceGPT Model.
      </p>
      <LoadingButton
        onClick={handleSummarize}
        loading={loading}
        text={loading ? "Generating..." : "Generate new deal brief"}
        className="summarize-button"
        style={{ marginBottom: "20px" }}
      />

      {loading && (
        <div style={{ marginTop: '20px', marginBottom: '20px' }}>
          <div style={{ marginBottom: '10px', fontWeight: 'bold' }}>
            {generationStep}...
          </div>
          <div style={{ 
            width: '100%', 
            height: '20px', 
            backgroundColor: '#f0f0f0',
            borderRadius: '10px',
            overflow: 'hidden'
          }}>
            <div style={{
              width: `${progress}%`,
              height: '100%',
              background: 'linear-gradient(90deg, #6902b9 0%, #1c71a1 100%)',
              transition: 'width 0.3s ease-in-out'
            }}></div>
          </div>
        </div>
      )}

      {error && <p className="error-text">{error}</p>}

      {summaries.map((summaryObj, index) =>
        renderSummaryDetails(summaryObj, index),
      )}
    </div>
  );
}
